import React, { useState, useRef, useEffect } from "react";
import { Stage, Layer, Group, Rect, Text, Transformer, Line, Circle } from "react-konva";
import { useSelector, useDispatch } from "react-redux";
import { apiRoute } from "../helpers";
import { getColorForValue } from "../helpers/getColors";
import { v4 as uuidv4 } from "uuid";
import {
  addNewPathologies,
  updatePathologiesList,
  addFreehandPathology,
  updateFreehandDrawingsList,
  addNewRectanglesFromFreehand
} from "../store/actions/pathologies";

import { Spinner } from "react-bootstrap";

const BoundingBox = (props) => {
  const isDrawing = useRef(false);
  const [startPos, setStartPos] = useState();
  const [endPos, setEndPos] = useState();

  const dispatch = useDispatch();
  const nextImage = useSelector((state) => state.next.next);
  const [isDraggable, setIsDraggable] = useState(false);

  const selectedImage = useSelector((state) => state.images?.currentImage);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [selectedBoxId, setSelectedBoxId] = useState(null);

  const transformerRef = useRef(null);
  const stageRef = useRef(null);
  const bbRef = useRef(null);

  const [imageWidth, setWidth] = useState(0);
  const [imageHeight, setHeight] = useState(0);

  const [displayedWidth, setDisplayedWidth] = useState(0);
  const [displayedHeight, setDisplayedHeight] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isTransforming, setIsTransforming] = useState(false);

  const [isEditable, setIsEditable] = useState(false);
  const [editBox, setEditBox] = useState(null);

  const [displayAllRectangles, setDisplayAllRectangles] = useState(true);
  const [displayAllFreehand, setDisplayAllFreehand] = useState(true);

  const selectedRectangles = useSelector(
    (state) => state.pathologies.pathologies
  )

  const selectedLines = useSelector(
    (state) => state.pathologies.freehandDrawings
  )

  const pathologyOptions = useSelector((state) => state.selectionOptions.options.pathologies);

  const [points, setPoints] = useState([]);
  const [lines, setLines] = useState([]);
  const [ratioAdded, setRatioAdded] = useState([]);

  useEffect(() => {
    if (props.editId !== null && props.editId !== true) {
      setIsDraggable(props.editState);
    } else {
      setIsDraggable(false);
    }
  }, [props.editId, props.editState]);

  const handleDragStart = (e) => {
    const group = e.target.parent;
    setIsDraggable(true);
    setSelectedBoxId(selectedBoxId);
  };

  const handleDragEnd = (e) => {
    const group = e.target;

    const index = selectedRectangles.findIndex(
      (box) => box.id === selectedBoxId
    );

    const groupPosition = group.getAbsolutePosition();

    console.log("rect", index);

    const ratioX = imageWidth / displayedWidth;
    const ratioY = imageHeight / displayedHeight;

    const newState = [...selectedRectangles];
    const updatedBox = {
      ...selectedRectangles[index],
      x: groupPosition.x * ratioX,
      y: groupPosition.y * ratioY,
      width: newState[index].width,
      height: newState[index].height,
    };
    console.log('new boix', updatedBox)
    console.log('old boix', newState[index])
    newState[index] = updatedBox;

    dispatch(updatePathologiesList(newState));
  };

  const handleTransformStart = () => {
    setIsTransforming(true);
    const selectedBox = stageRef.current.findOne(`#${selectedBoxId}`);
    transformerRef.current?.nodes([selectedBox]);
  };

  useEffect(() => {
    setIsEditable(props.editState);
  },[props.editState])

  useEffect(() => {
  }, [isEditable])

  // useEffect(()=>{
  //   const boxToEdit = selectedRectangles.find(box => box.id === props.editId);
  //   if(boxToEdit && props.editState === true) {
  //     setEditBox(boxToEdit);
  //   } else {
  //     setEditBox(null);
  //   }
  // }, [props.editState, props.editId, selectedRectangles])

  // useEffect(() => {
  //   if (!isEditable && editBox) {
  //     const index = selectedRectangles.findIndex((box) => box.id === editBox.id);
  //     if (index !== -1) {
  //       const newState = [...selectedRectangles];
  //       newState[index] = editBox;
  //       dispatch(updatePathologiesList(newState));
  //     }
  //   }
  // }, [isEditable, editBox]);

  useEffect(() => {
    // Check both rectangles and freehand drawings for the box to edit
    const boxToEdit = selectedRectangles.find(box => box.id === props.editId);
    const drawingToEdit = selectedLines.find(drawing => drawing.id === props.editId);
  
    if ((boxToEdit || drawingToEdit) && props.editState === true) {
      setEditBox(boxToEdit || drawingToEdit);
    } else {
      setEditBox(null);
    }
  }, [props.editState, props.editId, selectedRectangles, selectedLines]);
  
  useEffect(() => {
    if (!isEditable && editBox) {
      const rectangleIndex = selectedRectangles.findIndex((box) => box.id === editBox.id);
      const drawingIndex = selectedLines.findIndex((drawing) => drawing.id === editBox.id);
  
      if (rectangleIndex !== -1) {
        const newState = [...selectedRectangles];
        newState[rectangleIndex] = editBox;
        dispatch(updatePathologiesList(newState));
      }
  
      if (drawingIndex !== -1) {
        const newState = [...selectedLines];
        newState[drawingIndex] = editBox;
        dispatch(updateFreehandDrawingsList(newState));
      }
    }
  }, [isEditable, editBox, selectedRectangles, selectedLines, dispatch]);

  const handleTransformEnd = (e) => {

    const ratioX = imageWidth / displayedWidth;
    const ratioY = imageHeight / displayedHeight;

    const group = e.target;
    const groupX = group.parent.attrs.x * ratioX;
    const groupY = group.parent.attrs.y * ratioY;
    const groupWidth = group.getClientRect().width * ratioX;
    const groupHeight = group.getClientRect().height * ratioY;

    const scaleX = group.scaleX()
    const scaleY = group.scaleY()

    group.scaleX(1);
    group.scaleY(1);
    
    const groupPos =group.getAbsolutePosition();
    const newState = [...selectedRectangles];
  
    const index = newState.findIndex((box) => box.id === selectedBoxId);
  
    if (index !== -1) {

        const box = newState[index];
    
        const updatedBox = {
          ...box,
          x: groupX,
          y: groupY,
          width: groupWidth,
          height: groupHeight,
        };

        newState[index] = updatedBox;
  
      dispatch(updatePathologiesList(newState));
    }
  
    if(!isEditable) {
      transformerRef.current.detach();
    }
  };

  useEffect(() => {
    console.log('updated selectedRectangles', selectedRectangles);
    
  }, [selectedRectangles]);

  const handleDragMove = (e) => {
    setIsDraggable(true);
  };

  const pathologies = selectedImage?.pathologies;
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (pathologies) {
     // Inverse ratios for scaling to the current display size
    const ratioX = displayedWidth / imageWidth;
    const ratioY = displayedHeight / imageHeight;

    const newGroups = pathologies?.pathologies?.map((pathology, i) => (
      <Group key={i}>
        <Rect
          key={i}
          x={pathology.coords.x * ratioX}
          y={pathology.coords.y * ratioY}
          width={pathology.coords.w * ratioX}
          height={pathology.coords.h * ratioY}
          stroke={getColorForValue(
            pathology.name,
            pathologyOptions
          )}
          strokeWidth={2}
        />
      </Group>
    ));
    setGroups(newGroups);
    }
  }, [isDraggable, pathologies, pathologies?.pathologies]);

  useEffect(() => {
  }, [selectedBoxId]);

  useEffect(() => {
    if (isDraggable && props.editId) {
      setSelectedBoxId(props.editId);
      if (selectedBoxId) {
        const selectedGroup = stageRef.current.findOne(`#${selectedBoxId}`);
        if (selectedGroup) {
          transformerRef.current?.nodes([selectedGroup]);
          transformerRef.current?.getLayer().batchDraw();
        } else {
          setSelectedBoxId(null); 
        }
      } else {
        console.log('Selected box id is null');
      }
    }
  }, [selectedBoxId, isDraggable, props.editId]);

  const handleMouseDown = (e) => {
    if (props.isFreeHand && props.pathologyOption && props.pathologyOption !== "Other") {
      if (!isDrawing.current) {
        isDrawing.current = true;
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        setPoints([point.x, point.y]);
        setDisplayAllFreehand(false)
      }
    } else if (!props.isFreeHand && props.pathologyOption && props.pathologyOption !== "Other") {
      if (!isDrawing.current) {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setStartPos(pos);
        setEndPos(pos);
        setDisplayAllRectangles(false);
      } else {
        const pos = e.target.getStage().getPointerPosition();
        setStartPos(pos);
        setEndPos(pos);
      }
    }
  };

  // const handleMouseMove = (e) => {
  //   if (!isDrawing.current) {
  //     return;
  //   } else {
  //     if(props.isFreeHand) {
  //       if (isDrawing.current) {
  //         const stage = e.target.getStage();
  //         const point = stage.getPointerPosition();
  //         setPoints((prevPoints) => [...prevPoints, point.x, point.y]);
  //       }
  //     } else {
  //       const pos = e.target.getStage().getPointerPosition();
  //       setEndPos(pos);
  //     }
  //   }
  // };

  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return;
    }
  
    if (props.isFreeHand && props.pathologyOption && props.pathologyOption !== "Other") {
      const stage = e.target.getStage();
      const point = stage.getPointerPosition();
      setPoints((prevPoints) => [...prevPoints, point.x, point.y]);
    } else if (!props.isFreeHand && props.pathologyOption && props.pathologyOption !== "Other") {
      const pos = e.target.getStage().getPointerPosition();
      setEndPos(pos);
    }
  };

  useEffect(() => {
    if (selectedImage) {
      setBackgroundImage(() => `url(${apiRoute()}/image/${selectedImage._id})`);
      const img = new Image();
      img.src = `${apiRoute()}/image/${selectedImage._id}`;
      img.onload = () => {
        setWidth(img.width);
        setHeight(img.height);
      }
      setWidth(img.width);
      setHeight(img.height);
    }
  }, [dispatch, selectedImage]);

  useEffect(() => {
    if (nextImage) {
      setIsLoading(true);
      const img = new Image();
      img.src = `${apiRoute()}/image/${nextImage.image._id}`;
      img.onload = () => {
        setIsLoading(false);
        setBackgroundImage(() => `url(${apiRoute()}/image/${nextImage.image._id})`);
        setWidth(img.width);
        setHeight(img.height);
      };
    }
  }, [nextImage]);

  const observeSize = (entries) => {
    for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setDisplayedWidth(width);
        setDisplayedHeight(height);
    }
};

const resizeObserver = new ResizeObserver(observeSize);
const current =  bbRef.current;

  useEffect(() => {
    if (current) {
        resizeObserver.observe(current);
    }

    return () => {
        if (resizeObserver && current) {
            resizeObserver.unobserve(current);
        }
    };
}, [current, resizeObserver]);

  // const handleMouseUp = (e) => {
  //   if(props.isFreeHand) {
  //       if (isDrawing.current && points.length > 4) {
  //         isDrawing.current = false;
  //         setDisplayAllRectangles(true);

  //         // Generate a single ID to use for both the freehand drawing and the respective rectangle object
  //         const commonId = uuidv4();
        
  //         // Close the path for the polygon by adding the first point at the end
  //         const closedPoints = [...points, points[0], points[1]];

  //         const xs = closedPoints.filter((_, index) => index % 2 === 0); 
  //         const ys = closedPoints.filter((_, index) => index % 2 !== 0);
  
  //         const freehandPoints = closedPoints.map((val, index) => ({
  //           x: closedPoints[index],
  //           y: closedPoints[index + 1]
  //         })).filter((_, i) => i % 2 === 0);  // Transform points array into objects with x and y

  //         console.log('freehandpoints', freehandPoints);

  //         const ratioX = imageWidth / displayedWidth;

  //         const scaledPoints = freehandPoints.map(point => ({
  //           x: point.x * ratioX,
  //           y: point.y * ratioX
  //         }));

  //         console.log('scaledPoints', scaledPoints);
        
  //         const newShape = {
  //           id: commonId,
  //           points: scaledPoints, // Use closedPoints for a filled shape
  //           stroke: props.labelColor || "#df4b26",
  //           strokeWidth: 2,
  //           fill: props.labelColor || "#df4b26", // Use a semi-transparent fill for visibility
  //           opacity: 0.5,
  //           text: props.pathologyOption,
  //           imgId: selectedImage._id
  //         };

  //         console.log('lines', lines);
  //         console.log('points', points);
  //         // console.log('newShape', newShape);

  //         // Add the shape to lines
  //         setLines([...lines, newShape]);

  //         console.log('newshape', newShape);
                
  //         // Dispatch the new shape to Redux store
  //         dispatch(addFreehandPathology(newShape));
                
  //         // Clear points state for the next drawing session
  //         setPoints([]);

  //         const minX = Math.min(...xs);
  //         const minY = Math.min(...ys);
  //         const maxX = Math.max(...xs);
  //         const maxY = Math.max(...ys);
    
  //         if (minX !== maxX && minY !== maxY) {
  //           const ratioX = imageWidth / displayedWidth;
  //           // const ratioY = imageHeight / displayedHeight;
  //           // const rectangleId = uuidv4();
            
  //           const x = Math.min(minX, maxX) * ratioX;
  //           const y = Math.min(minY, maxY) * ratioX;
  //           const width = Math.abs(maxX - minX) * ratioX;
  //           const height = Math.abs(maxY - minY) * ratioX;
          
  //           if (width < 10 && height < 10) {
  //             return;
  //           }
            
  //           let newRect = {
  //             id: commonId,
  //             x,
  //             y,
  //             width,
  //             height,
  //             color: props.labelColor,
  //             text: props.pathologyOption,
  //             imgId: selectedImage._id
  //           };
          
  //           if (newRect.text !== "") {
  //             dispatch(addNewRectanglesFromFreehand([newRect]));
      
  //             // Notify MainComponent about the action to revert to initial state of Pathology dropdown
  //             props.onMouseUp();
  //           }
  //         }
        
  //         setLines([...lines, newShape]);
  //         setPoints([]);
        
  //         // Notify parent component to reset the selected pathology option
  //         props.onPathologyDrawn && props.onPathologyDrawn();
        
  //         // dispatch(addNewPathologies([newShape])); // Adjust based on your actual implementation
  //       }
  //   }
  //   else {
  //     isDrawing.current = false;
  //     setDisplayAllRectangles(true);
  
  //     if (startPos.x !== endPos.x && startPos.y !== endPos.y) {
  //       const ratioX = imageWidth / displayedWidth;
  //       console.log('ratioX: ', ratioX)
  //       // const ratioY = imageHeight / displayedHeight;
  //       const rectangleId = uuidv4();
        
  //       const x = Math.min(startPos.x, endPos.x) * ratioX;
  //       const y = Math.min(startPos.y, endPos.y) * ratioX;
  //       const width = Math.abs(endPos.x - startPos.x) * ratioX;
  //       const height = Math.abs(endPos.y - startPos.y) * ratioX;
      
  //       if (width < 10 && height < 10) {
  //         return;
  //       }
        
  //       let newRect = {
  //         id: rectangleId,
  //         x,
  //         y,
  //         width,
  //         height,
  //         color: props.labelColor,
  //         text: props.pathologyOption,
  //         imgId: selectedImage._id
  //       };
      
  //       if (newRect.text !== "") {
  //         dispatch(addNewPathologies([newRect]));
  
  //         // Notify MainComponent about the action to revert to initial state of Pathology dropdown
  //         props.onMouseUp();
  //       }
  //     }
  // }};
  
  const handleMouseUp = (e) => {
    if (props.isFreeHand) {
      if (isDrawing.current && points.length > 4) {
        isDrawing.current = false;
        setDisplayAllRectangles(true);
        setDisplayAllFreehand(true);
  
        const commonId = uuidv4();
        const closedPoints = [...points, points[0], points[1]];
        const xs = closedPoints.filter((_, index) => index % 2 === 0);
        const ys = closedPoints.filter((_, index) => index % 2 !== 0);
        const freehandPoints = closedPoints.map((val, index) => ({
          x: closedPoints[index],
          y: closedPoints[index + 1]
        })).filter((_, i) => i % 2 === 0);
  
        const ratioX = imageWidth / displayedWidth;
        const scaledPoints = freehandPoints.map(point => ({
          x: point.x * ratioX,
          y: point.y * ratioX
        }));
  
        const newShape = {
          id: commonId,
          points: scaledPoints,
          stroke: props.labelColor || "#df4b26",
          strokeWidth: 2,
          fill: props.labelColor || "#df4b26",
          opacity: 0.5,
          text: props.pathologyOption,
          imgId: selectedImage._id
        };
  
        setLines([...lines, newShape]);
        dispatch(addFreehandPathology(newShape));
  
        setPoints([]);
        const minX = Math.min(...xs);
        const minY = Math.min(...ys);
        const maxX = Math.max(...xs);
        const maxY = Math.max(...ys);
  
        if (minX !== maxX && minY !== maxY) {
          const x = Math.min(minX, maxX) * ratioX;
          const y = Math.min(minY, maxY) * ratioX;
          const width = Math.abs(maxX - minX) * ratioX;
          const height = Math.abs(maxY - minY) * ratioX;
  
          if (width < 10 && height < 10) {
            return;
          }
  
          let newRect = {
            id: commonId,
            x,
            y,
            width,
            height,
            color: props.labelColor,
            text: props.pathologyOption,
            imgId: selectedImage._id
          };
  
          if (newRect.text !== "") {
            dispatch(addNewRectanglesFromFreehand([newRect]));
            props.onMouseUp();
          }
        }
  
        setLines([...lines, newShape]);
        setPoints([]);
        props.onPathologyDrawn && props.onPathologyDrawn();
      }
    } else {
      isDrawing.current = false;
      setDisplayAllRectangles(true);
      setDisplayAllFreehand(true);

      if (!startPos || !endPos || startPos.x === undefined || endPos.x === undefined || startPos.y === undefined || endPos.y === undefined) {
        return; // Ensure startPos and endPos are defined before using them
      }
  
      if (startPos.x !== endPos.x && startPos.y !== endPos.y) {
        const ratioX = imageWidth / displayedWidth;
        const rectangleId = uuidv4();
        const x = Math.min(startPos.x, endPos.x) * ratioX;
        const y = Math.min(startPos.y, endPos.y) * ratioX;
        const width = Math.abs(endPos.x - startPos.x) * ratioX;
        const height = Math.abs(endPos.y - startPos.y) * ratioX;
  
        if (width < 10 && height < 10) {
          return;
        }
  
        let newRect = {
          id: rectangleId,
          x,
          y,
          width,
          height,
          color: props.labelColor,
          text: props.pathologyOption,
          imgId: selectedImage._id
        };
  
        if (newRect.text !== "") {
          dispatch(addNewPathologies([newRect]));
          props.onMouseUp();
        }
      }
    }
  };
  
  useEffect(() => {
    // console.log('displayAllRectangles updated:', displayAllRectangles);
}, [displayAllRectangles]);
  
  const rectanglesToRender = editBox ? [editBox] : selectedRectangles;
  const linesToRender = editBox ? [editBox] : selectedLines;

  useEffect(() =>{
    console.log('rectanglesToRender', rectanglesToRender)
  }, [rectanglesToRender])

  useEffect(() =>{
    console.log('rectanglesToRender', rectanglesToRender)
  }, [lines])

  useEffect(() => {
    // console.log('showBoxes', props.showBoxes);
  }, [props.showBoxes])

  useEffect(() => {
    const canDraw = props.pathologyOption !== "Other";
    console.log("pathologyOption", props.pathologyOption);
    if (!canDraw) {
      isDrawing.current = false;
    }
  }, [props.pathologyOption]);

  const calculateCentroidX = (points, ratio) => {
    let sumX = 0;
    for (let i = 0; i < points.length; i++) {
      sumX += points[i].x * ratio;
    }
    return sumX / points.length;
  };
  
  const calculateCentroidY = (points, ratio) => {
    let sumY = 0;
    for (let i = 0; i < points.length; i++) {
      sumY += points[i].y * ratio;
    }
    return sumY / points.length;
  };
  
  const calculateBoundingBox = (points, ratio) => {
    let minX = points[0].x * ratio, maxX = points[0].x * ratio;
    let minY = points[0].y * ratio, maxY = points[0].y * ratio;
  
    for (let i = 1; i < points.length; i++) {
      const x = points[i].x * ratio;
      const y = points[i].y * ratio;
      if (x < minX) minX = x;
      if (x > maxX) maxX = x;
      if (y < minY) minY = y;
      if (y > maxY) maxY = y;
    }
  
    return { minX, maxX, minY, maxY };
  };
  
  const calculateFontSize = (points, text, ratio) => {
    const { minX, maxX } = calculateBoundingBox(points, ratio);
    const textWidth = maxX - minX;
    const estimatedCharWidth = textWidth / text.length;
    const fontSize = Math.min(estimatedCharWidth * 1.5, 20);
  
    return fontSize;
  };

  const inverseRatioX = displayedWidth / imageWidth;

const renderAnchors = (shape) => {

  const anchors = shape.points.filter((_, index) => index % 24 === 0);
  return (
    <>
      {anchors.map((point, index) => (
        <Circle
          key={index}
          x={point.x * inverseRatioX}
          y={point.y * inverseRatioX}
          width={10}
          height={10}
          fill="blue"
          draggable
          onDragMove={(e) => handleAnchorDragMove(e, shape.id, index * 24)}
          onDragEnd={(e) => handleAnchorDragMove(e, shape.id, index * 24)}
        />
      ))}
      {anchors.map((point, index) => {
        if (index < anchors.length - 1) {
          const nextPoint = anchors[index + 1];
          return (
            <Line
              key={`line-${index}`}
              points={[
                point.x * inverseRatioX,
                point.y * inverseRatioX,
                nextPoint.x * inverseRatioX,
                nextPoint.y * inverseRatioX
              ]}
              stroke="blue"
              strokeWidth={2}
            />
          );
        }
        return null;
      })}
    </>
  );
};

  
const handleAnchorDragMove = (e, shapeId, anchorIndex) => {
  const newPoints = selectedLines.map(shape => {
    if (shape.id === shapeId) {
      const updatedPoints = [...shape.points];
      const draggedPoint = {
        x: e.target.x() / inverseRatioX,
        y: e.target.y() / inverseRatioX
      };

      const deltaX = draggedPoint.x - updatedPoints[anchorIndex].x;
      const deltaY = draggedPoint.y - updatedPoints[anchorIndex].y;

      updatedPoints[anchorIndex] = draggedPoint;

      const influenceRange = 24; // Number of points to influence on each side
      for (let i = 1; i <= influenceRange; i++) {
        if (anchorIndex - i >= 0) {
          const factor = (influenceRange - i + 1) / (influenceRange + 1);
          updatedPoints[anchorIndex - i] = {
            x: updatedPoints[anchorIndex - i].x + deltaX * factor,
            y: updatedPoints[anchorIndex - i].y + deltaY * factor
          };
        }
        if (anchorIndex + i < updatedPoints.length) {
          const factor = (influenceRange - i + 1) / (influenceRange + 1);
          updatedPoints[anchorIndex + i] = {
            x: updatedPoints[anchorIndex + i].x + deltaX * factor,
            y: updatedPoints[anchorIndex + i].y + deltaY * factor
          };
        }
      }

      return { ...shape, points: updatedPoints };
    }
    return shape;
  });

  setLines(newPoints);
  dispatch(updateFreehandDrawingsList(newPoints));
};


  return (
    <div className="bounding-box" ref={bbRef} 
    style={{
      height: displayedWidth/(imageWidth/imageHeight)
    }}
    >
      {isLoading ? (
        <div className="spinner">
          <div
            style={{
              textAlign: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              margin: "-4rem 0 0 -4rem",
            }}
          >
            <Spinner
              style={{
                width: "8rem",
                height: "8rem",
              }}
              animation="border"
              variant="primary"
            />
            <h5 style={{ marginTop: "2rem", color: "white" }}>
              Loading Next Image...
            </h5>
          </div>
        </div>
      ) : (
        <div class='mg-bg'
        style={{
            backgroundImage: isLoading ? "" : backgroundImage,
            filter: `brightness(${props.brightness}%) contrast(${props.contrast}%) saturate(${props.saturate}%) sepia(${props.sepia}%) invert(${props.invert}%)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: '100%',
            backgroundPosition: "start",
        }}
        >
        <Stage
          ref={stageRef}
          style={{
          }}
          width={displayedWidth} // Use state values here
          height={displayedWidth/(imageWidth/imageHeight)}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          {isLoading && <Spinner animation="border" variant="primary" />}

          {props.isFreeHand
            ? displayAllFreehand && props.showBoxes && linesToRender.map((shape, idx) => {
              if(shape.imgId === selectedImage._id) {
                const index = idx + 1;
                return (
                  <Layer key={shape.id}>
                    <Line
                      points={shape.points?.flatMap(({ x, y }) => [x * inverseRatioX, y * inverseRatioX])}
                      stroke={shape.stroke}
                      strokeWidth={shape.strokeWidth}
                      fill={shape.fill}
                      closed={true}
                      opacity={shape.opacity}
                      tension={0.5}
                      lineCap="round"
                      lineJoin="round"
                    />
                    {shape.points.length > 2 && (
                      <Text
                        x={calculateCentroidX(shape.points, inverseRatioX)}
                        y={calculateCentroidY(shape.points, inverseRatioX)}
                        text={`${index} ${shape.text}`}
                        fontSize='20'
                        fontFamily="Arial"
                        fill='#fff'
                        align="center"
                        verticalAlign="middle"
                        z-index='100'
                      />
                    )}
                    {isEditable && renderAnchors(shape)}
                  </Layer>
                );
              }
            })
          : (
              <Layer>
                {selectedBoxId && isDraggable && (
                  <Transformer
                    ref={transformerRef}
                    onTransformStart={handleTransformStart}
                    onTransformEnd={handleTransformEnd}
                    ignoreStroke={true}
                    rotateEnabled={false}
                    enabledAnchors={['middle-right', 'bottom-center', 'bottom-right']}
                  />
                )}
                {displayAllRectangles && props.showBoxes && rectanglesToRender.map((rect, i) => {
                  console.log('displayAllRectangles:', displayAllRectangles, 'showBoxes:', props.showBoxes);
                  console.log('Rendering rectangle:', rect);
                  if (
                    (rect.imgId === selectedImage._id) &&
                    typeof imageWidth === 'number' &&
                    typeof displayedWidth === 'number' &&
                    typeof imageHeight === 'number' &&
                    typeof displayedHeight === 'number'
                  ) {
                    const ratioX = displayedWidth / imageWidth;
                    const displayX = rect.x * ratioX;
                    const displayY = rect.y * ratioX;
                    const displayWidth = rect.width * ratioX;
                    const displayHeight = rect.height * ratioX;
                    return (
                      <Group
                        key={i}
                        draggable={isDraggable}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        scaleX={1}
                        scaleY={1}
                        x={displayX}
                        y={displayY}
                      >
                        <Rect
                          key={i}
                          id={rect.id}
                          x={0}
                          y={0}
                          width={displayWidth}
                          height={displayHeight}
                          stroke={rect.color}
                          strokeWidth={2}
                        />
                        {selectedRectangles.map((text, j) => {
                          const textBackgroundWidth = displayWidth;
                          const textBackgroundHeight = 20;
                          if (text.id === rect.id) {
                            const index = selectedRectangles
                              .slice(0, i + 1)
                              .filter((r) => r.text === rect.text).length;
                            return (
                              <>
                                <Rect
                                  width={textBackgroundWidth}
                                  height={textBackgroundHeight}
                                  fill={`${rect.color}80`}
                                  x={0}
                                  y={0}
                                />
                                <Text
                                  x={0}
                                  y={0}
                                  fontSize={12}
                                  strokeWidth={0.5}
                                  fill="white"
                                  fontFamily="Calibri"
                                  text={`${index > 0 ? index : ''} ${text.text}`}
                                  padding={5}
                                />
                              </>
                            );
                          }
                        })}
                      </Group>
                    );
                  } else {
                    return null;
                  }
                })}
              </Layer>
            )}


          
          <Layer>
            {groups}
            {!isDraggable || !isTransforming ? (
              <Group>
                {isDrawing.current && props.pathologyOption && props.pathologyOption !== "Other" && (
                  ((props.isFreeHand) && points.length > 0 ) ? (
                    <Line
                      points={points}
                      stroke={props.labelColor || "#df4b26"}
                      strokeWidth={5}
                      tension={0.5}
                      lineCap="round"
                      lineJoin="round"
                    />
                  ) : 
                  (<Rect
                    x={Math.min(startPos.x, endPos.x)}
                    y={Math.min(startPos.y, endPos.y)}
                    width={Math.abs(endPos.x - startPos.x)}
                    height={Math.abs(endPos.y - startPos.y)}
                    stroke={props.labelColor}
                    strokeWidth={2}
                  />)
                )}
              </Group>
            ) : null}
          </Layer>
        </Stage>
        </div>
      )}
    </div>
  );
};

export default BoundingBox;