import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faScrewdriverWrench,
  faBars,
  faInfo,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import ProjectList from "../ProjectSelector/ProjectList/ProjectList";
import { useNavigate } from "react-router-dom";
import { getSelectorOptionsThunk } from "../../store/actions/selectorOptions";
import { setUserSelectedProject } from "../../store/actions/projects";
import { clearPathologiesList } from "../../store/actions/pathologies";
import { clearDiagnosesList } from "../../store/actions/diagnoses";
import { clearTagsList } from "../../store/actions/tags";
import styles from "./Selector.module.scss";
import { handleSliderToggles } from "../../store/actions/slider";
import { getNextImage, clearCurrentImage } from "../../store/actions/images/next";
import UserManual from "../../assets/user manual/UserManual.pdf";

const token = localStorage.getItem("token");

const Selector = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { projects } = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPathologiesOpen, setIsPathologiesOpen] = useState(false);
  const [isDiagnosisOpen, setIsDiagnosisOpen] = useState(false);
  const [isTagsOpen, setIsTagsOpen] = useState(false);
  const [isEnhancementsOpen, setIsEnhancementsOpen] = useState(false);
  const selectedImage = useSelector((state) => state.images?.currentImage);
  const [showPrompt, setShowPrompt] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showSelector, setShowSelector] = useState(false);

  const currentImage = useSelector((state) => state.next.next);
  const projectAtLoginData = useSelector(
    (state) => state.projects.projectSelectedAtLogin
  );

  const selectedPathologies = useSelector(
    (state) => state.pathologies.pathologies
  );

  const selectedDiagnoses = useSelector((state) => state.diagnoses.diagnoses);

  const tags = useSelector((state) => state.tags.tags);

  const handleProjectSelect = async (projectId) => {
    if (
      selectedPathologies.length > 0 ||
      selectedDiagnoses.length > 0 ||
      tags.length > 0
    ) {
      setShowPrompt(true);
      setShowSelector(false);
      dispatch(handleSliderToggles(true));
      setSelectedOption(projectId)
      return; // Exit early, don't switch the project until user decides
    }
    proceedWithProjectSelection(projectId);
  };

  const handleProceed = () => {
    // Clear all pathologies, diagnoses, and tags
    dispatch(clearPathologiesList());
    dispatch(clearDiagnosesList());
    dispatch(clearTagsList());

    setShowPrompt(false);

    if (selectedOption) {
      proceedWithProjectSelection(selectedOption);
    }
  };

  const proceedWithProjectSelection = async (projectId) => {
    setShowSelector(true);
    navigate(`/authorized/projects/${projectId}`);
    await dispatch(setUserSelectedProject(projectId));
    await dispatch(getSelectorOptionsThunk(projectId));
    dispatch(handleSliderToggles(true));
    dispatch(clearCurrentImage(null));
    dispatch(getNextImage(token, projectId, projectAtLoginData));
    setShowSelector(false); 
  };

  const openProjectSelect = () => {
    setShowSelector(!showSelector);
  };

  useEffect(() => {
    const anyModalOpen =
      isPathologiesOpen || isDiagnosisOpen || isTagsOpen || isEnhancementsOpen;
    setIsOpen(anyModalOpen);
    props.updateStateValue(anyModalOpen);
  }, [isPathologiesOpen, isDiagnosisOpen, isTagsOpen, isEnhancementsOpen]);

  const expandAllTools = () => {
    if (isOpen) {
      setIsPathologiesOpen(false);
      props.updatePathologyValue(false);
      setIsDiagnosisOpen(false);
      props.updateDiagnosisValue(false);
      setIsTagsOpen(false);
      props.updateTagValue(false);
      setIsEnhancementsOpen(false);
      props.updateEnhancementValue(false);
    } else {
      setIsPathologiesOpen(true);
      props.updatePathologyValue(true);
      setIsDiagnosisOpen(true);
      props.updateDiagnosisValue(true);
      setIsTagsOpen(true);
      props.updateTagValue(true);
      setIsEnhancementsOpen(true);
      props.updateEnhancementValue(true);
    }
  };

  const openContactUsLink = () => {
    window.open("https://envisionit.ai/contact-us/", "_blank");
  };

  const downloadFile = (url, filename) => {
    // Create a new anchor element
    const anchor = document.createElement('a');
  
    // Set the href to the URL of the file you want to download
    anchor.href = url;
  
    // Use the filename parameter to set the downloaded file name
    // If the filename is not provided, it will be automatically determined by the browser.
    if (filename) {
      anchor.download = filename;
    }
  
    // Append the anchor to the body, click it, and then remove it
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <div id="addButton" className={`fab open`}>
      <div className="menu">
        <button disabled={props.isSliderLocked === true} className="pathologies" onClick={expandAllTools}>
          <FontAwesomeIcon icon={faScrewdriverWrench} size="lg" />
          <span>Expand All</span>
        </button>
        <button onClick={() => openProjectSelect()}>
          <FontAwesomeIcon icon={faBars} size="lg" />
          <span>Projects</span>
        </button>
        <button onClick={openContactUsLink}>
          <FontAwesomeIcon icon={faInfo} size="lg" />
          <span>Contact Us</span>
        </button>
        <button onClick={() => downloadFile(UserManual, 'UserManual.pdf')}>
          <FontAwesomeIcon icon={faBook} size="lg" />
          <span>User Guide</span>
        </button>
      </div>
      {showSelector && (
        <ProjectList
          handleProjectSelect={handleProjectSelect}
          options={projects}
          className={styles.projectSelector}
        />
      )}
      {showPrompt && (
        <div className={styles.prompt}>
          <p>
            Switching to another project without saving changes will remove any
            annotations. Would you like to proceed?
          </p>
          <div className={styles.prompts}>
            <button onClick={() => setShowPrompt(false)}>Cancel</button>
            <button onClick={handleProceed}>Proceed</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Selector;
