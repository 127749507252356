import React, { useState, useEffect } from "react";
import Selector from "./Selector/Selector";
import Slider from "./Slider";
import BoundingBox from "./BoundingBox";
import NewWindow from "react-new-window";
import { useSelector, useDispatch } from "react-redux";
import { getImageCount } from "../store/actions/count";
import ImageList from "./ImageList/ImageList";
import { getNextImage } from "../store/actions/images/next";
import { getPreviousImages } from "../store/actions/images/previous";
import { getSelectedBoundingBox } from "../store/actions/pathologies";
import { clearCurrentImage } from "../store/actions/images/next";

const Main = () => {
  const count = useSelector((state) => state?.count?.count);
  const images = useSelector((state) => state.previous.previous);
  const dispatch = useDispatch();
  const currentImage = useSelector((state) => {
    return state.next.next;
  });
  const projectId = useSelector((state) => state.projects.selectedProject);

  const { projects } = useSelector((state) => state.projects);

  const project = projects.find((proj) => proj._id === projectId);
  const selectedImage = useSelector((state) => state.images?.currentImage);
  const previousImages = useSelector((state) => state.previous?.previous);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [diagnosisValue, setDiagnosisValue] = useState(false);
  const [pathologyValue, setPathologyValue] = useState(false);
  const [labelColor, setLabelColor] = useState();
  const [pathologyOption, setPathologyOption] = useState("");
  const [diagnosisOption, setDiagnosisOption] = useState("");
  const [bbText, setBBText] = useState([]);
  const [tagValue, setTagValue] = useState(false);
  const [enhancementValue, setEnhancementValue] = useState(false);
  const [hasId, setHasId] = useState(null);
  const [isEditable, setIsEditable] = useState(false);

  const [isDrawingNow, setIsDrawingNow] = useState(false);
  const [showBoundingBoxes, setShowBoundingBoxes] = useState(true);

  const [showLastCreated, setShowLastCreated] = useState(false);

  const [brightness, setBrightness] = useState(100);
  const [contrast, setContrast] = useState(100);
  const [saturate, setSaturate] = useState(100);
  const [sepia, setSepia] = useState(0);
  const [invert, setInvert] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  const [isPathologiesOpen, setIsPathologiesOpen] = useState(false);
  const [isDiagnosisOpen, setIsDiagnosisOpen] = useState(false);
  const [isTagsOpen, setIsTagsOpen] = useState(false);
  const [isEnhancementsOpen, setIsEnhancementsOpen] = useState(false);

  const token = localStorage.getItem("token");

  const imageStyle = {
    filter: `brightness(${brightness}%) contrast(${contrast}%) saturate(${saturate}%) sepia(${sepia}%) invert(${invert}%)`,
  };

  const [openNewWindow, setOpenNewWindow] = useState(false);

  const [resetSliderTrigger, setResetSliderTrigger] = useState(false);

  const handleResetSlider = () => {
    setResetSliderTrigger(prev => !prev); // Toggle to trigger useEffect in Slider
    setPathologyOption("");
    setShowBoundingBoxes(true);
  };

  const projectAtLoginData = useSelector(
    (state) => state.projects.projectSelectedAtLogin
  );

  const [isSliderLocked, setIsSliderLocked] = useState(false); // State to manage slide-out lock

  const toggleSliderLock = () => setIsSliderLocked(!isSliderLocked); // Function to slide-out toggle

  const drawingFlagCheck = useSelector(
    (state) => state.selectionOptions.options.freehand_enabled
  );

  const [isFreeHand, setIsFreeHand] = useState(drawingFlagCheck);

  useEffect(() => {
    setIsFreeHand(drawingFlagCheck);
  }, [drawingFlagCheck]);

  const handleWindowClose = () => {
    setOpenNewWindow(false);
  };

  const handleEditId = async (value) => {
    await dispatch(getSelectedBoundingBox(value));
    setHasId(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(clearCurrentImage(null));
      console.log('clear2', projectId)
      console.log('clear2', previousImages.findIndex(p => p.image?._id === selectedImage?._id) > 0)
      await dispatch(getNextImage(token, projectId, projectAtLoginData));
    };

    fetchData();
  }, [dispatch, projectId, token, projectAtLoginData]);

  // useEffect(() => {
  //   // get the next Image as part of the next images and more.

  //   const handleGetNextImage = async () => {
  //     try {

  //       console.log('clear3', projectId)
  //       await dispatch(getNextImage(token, projectId, projectAtLoginData));
  //     } catch (error) {
  //       // console.log(error);
  //     }
  //   };
  //   handleGetNextImage();
  // }, [dispatch, projectId, token, projectAtLoginData]);

  const handleEditState = (value) => {
    setIsEditable(value);
  };

  const handleNewEditState = (value) => {
    setHasId(value);
  };


  useEffect(() => {
    const handleGetImageCount = async () => {
      setIsLoadingCount(true);
      try {
        await dispatch(getImageCount(token, projectId));
        setIsLoadingCount(false);
      } catch (error) {
        setIsLoadingCount(false);
        // console.log(error);
      }
    };
    handleGetImageCount();
  }, [dispatch, projectId, token]);

  const updateStateValue = (value) => {
    setIsModalOpen(value);
  };

  const handleCloseSlider = () => {
    setIsModalOpen(false);
    setIsPathologiesOpen(false);
    setIsDiagnosisOpen(false);
    setIsTagsOpen(false);
    setIsEnhancementsOpen(false);
  };

  const updatePathologyValue = (value) => {
    setPathologyValue(value);
  };

  const updateDiagnosisValue = (value) => {
    setDiagnosisValue(value);
  };

  const getPathologyColor = (value) => {
    setLabelColor(value.color);
  };

  const getPathologyLabel = (value) => {
    setPathologyOption(value.label);
    console.log('check the pathology', value.label);
    console.log('check the arg', value);
  };

  const getDiagnosisOptions = (value) => {
    setDiagnosisOption(value.value);
  };

  const getTextOption = (value) => {
    setBBText(value);
  };

  const updateTagValue = (value) => {
    setTagValue(value);
  };

  const updateEnhancementValue = (value) => {
    setEnhancementValue(value);
  };

  useEffect(() => {
    if(pathologyOption) {
      console.log("pathologyOption", pathologyOption)
    }
  }, [pathologyOption])

  const handleDropdownChange = (pathOption) => {
    if (pathOption !== "initialStateOption") {
      setShowBoundingBoxes(false);
    }
  };

  useEffect(() => {
    if (currentImage) {
      const _currentImage = Object.assign({ pID: projectId }, currentImage);
      dispatch(getPreviousImages(_currentImage));
    }
  }, [currentImage, dispatch]);

  return (
    <main role="main" className="main">
      <div id="MainView">
        <nav className="navbar px-4 py-3 navbar-dark navbar-expand-lg">
          <li className="form-inline navy-fields">
            <form className="form-inline">
              <div role="group" className="input-group rounded-pill">
                <div className="input-group-prepend">
                  <div className="input-group-text navy-fields form-control prepend">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="search"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="svg-inline--fa fa-search fa-w-16"
                    >
                      <path
                        fill="currentColor"
                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                        class=""
                      ></path>
                    </svg>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="__BVID__25"
                  placeholder="Search by project name..."
                  aria-label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className={`input-group-append`}>
                  <div className="input-group-text navy-fields form-control append"></div>
                </div>
                <div style={{ color: "#0473b4", margin: "5px 5px 5px 15px" }}>
                  {projectId
                    ? `Project: ${project.name}`
                    : "no project selected"}
                  <span
                    className="badge ml-2 bg-yellow color-black font-weight-400 badge-secondary badge-pill"
                    title="images left to label in this project"
                  >
                    {count ? count : 0}
                  </span>
                </div>
              </div>
            </form>
          </li>

          <button
            type="button"
            aria-label="Toggle navigation"
            className="navbar-toggler collapsed"
            aria-expanded="false"
            aria-controls="nav-collapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {!openNewWindow && (
            <Slider
              isModalOpen={isModalOpen}
              pathologyValue={pathologyValue}
              diagnosisValue={diagnosisValue}
              tagValue={tagValue}
              enhancementValue={enhancementValue}
              hasId={hasId}
              onClose={handleCloseSlider}
              getPathologyColor={getPathologyColor}
              getPathologyLabel={getPathologyLabel}
              getDiagnosisOptions={getDiagnosisOptions}
              bbText={bbText}
              labelColor={labelColor}
              handleEditId={handleEditId}
              handleEditState={handleEditState}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              brightness={brightness}
              setBrightness={setBrightness}
              contrast={contrast}
              setContrast={setContrast}
              saturate={saturate}
              setSaturate={setSaturate}
              sepia={sepia}
              setSepia={setSepia}
              invert={invert}
              setInvert={setInvert}
              isPathologiesOpen={isPathologiesOpen}
              isDiagnosisOpen={isDiagnosisOpen}
              isTagsOpen={isTagsOpen}
              isEnhancementsOpen={isEnhancementsOpen}
              resetTrigger={resetSliderTrigger}
              onChange={handleDropdownChange}
              isSliderLocked={isSliderLocked}
              toggleSliderLock={toggleSliderLock}
              isFreeHand={isFreeHand}
              // onToggle={handleToggle}
            />
          )}
        </nav>
        <div className="px-4 inner-container">
          <div className="row inner-row">
            <div
              className={`div1 inner-col d-flex flex-column ${
                openNewWindow ? "col-6" : "col-4"
              }`}
            >
              <div
                role="tablist"
                className="accordion flex-column bg-dark-navy left-div"
              >
                <header role="tab" className="card-header p-0 flex-shrink-1">
                  <button
                    type="button"
                    className="btn btn-secondary btn-block"
                    aria-expanded="false"
                    aria-controls="accordion-1"
                    data-target="#accordion-1"
                  >
                    Current
                    {isLoadingCount ? (
                      <small
                        className="ml-1 spinner-border spinner-border-sm text-primary"
                        role="status"
                      ></small>
                    ) : (
                      <span className="badge ml-2 bg-yellow color-black font-weight-400 badge-secondary badge-pill">
                        {previousImages?.length ? previousImages?.length : 0}
                        {/* of&nbsp;
                        {count ? count : 0} */}
                      </span>
                    )}
                  </button>
                </header>

                <div
                  className="flex-grow-1 overflow-y scroll-overflow image-h"
                  role="tabpanel"
                >
                  <div className="image-list-parent">
                    <ImageList
                      imagesData={images}
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                    />
                  </div>
                </div>
              </div>
            </div>
            {openNewWindow && (
              <div className="col-6">
                <Slider
                  openNewWindow={openNewWindow}
                  isModalOpen={isModalOpen}
                  pathologyValue={pathologyValue}
                  diagnosisValue={diagnosisValue}
                  tagValue={tagValue}
                  enhancementValue={enhancementValue}
                  hasId={hasId}
                  onClose={handleCloseSlider}
                  getPathologyColor={getPathologyColor}
                  getPathologyLabel={getPathologyLabel}
                  getDiagnosisOptions={getDiagnosisOptions}
                  bbText={bbText}
                  labelColor={labelColor}
                  handleEditId={handleEditId}
                  handleEditState={handleEditState}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  brightness={brightness}
                  setBrightness={setBrightness}
                  contrast={contrast}
                  setContrast={setContrast}
                  saturate={saturate}
                  setSaturate={setSaturate}
                  sepia={sepia}
                  setSepia={setSepia}
                  invert={invert}
                  setInvert={setInvert}
                  resetTrigger={resetSliderTrigger}
                  onChange={handleDropdownChange}
                  isSliderLocked={isSliderLocked}
                  toggleSliderLock={toggleSliderLock}
                  isFreeHand={isFreeHand}
                  // onToggle={handleToggle}
                />
                <NewWindow onUnload={handleWindowClose}>
                  <div>
                    <div className="card h-100 bg-dark-navy custom border-0">
                      <div className="card-header">
                        <div className="row">
                          <div className="col">
                            <h6 className="color-white">
                              {selectedImage ? selectedImage.image.name : ""}
                            </h6>
                          </div>
                          <div className="text-right col"></div>
                        </div>
                      </div>
                      <BoundingBox
                        labelColor={labelColor}
                        pathologyOption={pathologyOption}
                        onMouseUp={handleResetSlider}
                        showBoxes={showBoundingBoxes}
                        getTextOption={getTextOption}
                        editId={hasId}
                        editState={isEditable}
                        setIsEditable={setIsEditable}
                        handleNewEditState={handleNewEditState}
                        imageStyle={imageStyle}
                        brightness={brightness}
                        contrast={contrast}
                        saturate={saturate}
                        sepia={sepia}
                        invert={invert}
                        isFreeHand={isFreeHand}
                      />
                    </div>
                  </div>
                </NewWindow>
              </div>
            )}
            {!openNewWindow && (
              <div className={`div2 col-8`}>
                <div className="card h-100 bg-dark-navy custom border-0">
                  <div className="card-header">
                    <div className="row">
                      <div className="col">
                        <h6 className="color-white">
                          {selectedImage ? selectedImage?.image?.name : ""}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <BoundingBox
                    labelColor={labelColor}
                    pathologyOption={pathologyOption}
                    onMouseUp={handleResetSlider}
                    showBoxes={showBoundingBoxes}
                    getTextOption={getTextOption}
                    editId={hasId}
                    editState={isEditable}
                    setIsEditable={setIsEditable}
                    handleNewEditState={handleNewEditState}
                    imageStyle={imageStyle}
                    brightness={brightness}
                    contrast={contrast}
                    saturate={saturate}
                    sepia={sepia}
                    invert={invert}
                    isFreeHand={isFreeHand}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Selector
        updateStateValue={updateStateValue}
        updatePathologyValue={updatePathologyValue}
        updateDiagnosisValue={updateDiagnosisValue}
        updateTagValue={updateTagValue}
        updateEnhancementValue={updateEnhancementValue}
        isSliderLocked={isSliderLocked}
      />
    </main>
  );
};

export default Main;
