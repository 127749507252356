import React, { useEffect, useState } from "react";
import DropdownSelect from "./DropdownSelect";
import FilmStrip from "./FilmStrip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEdit,
  faSpinner,
  faTrash,
  faSun,
  faStop,
  faCheck,
  faLockOpen,
  faLock,
  faExclamationCircle,
  faSquare,
  faRegular,
  faCircle,
  faPencil,
  faExternalLinkSquare
} from "@fortawesome/free-solid-svg-icons";
import DiagnosisChip from "./DiagnosisChip/DiagnosisChip";
import RCSlider from "rc-slider";
import { createImageLabel } from "../store/actions/label";
import { useDispatch, useSelector } from "react-redux";
import { getNextImage } from "../store/actions/images/next";
import { getImageCount } from "../store/actions/count";
import { Spinner } from "react-bootstrap";
import {
  updateLabelsImage,
  removeLabelsImage,
} from "../store/actions/images/previous";
import { updateCurrentImage } from "../store/reducers/images/imagesSlicer";
import { pathologyOtherOptions } from "../constants";
import {
  clearPathologiesList,
  addNewPathologies,
  clearFreehandPathologiesList,
  clearReactanglesFromFreehandList
} from "../store/actions/pathologies";
import { clearDiagnosesList } from "../store/actions/diagnoses";
import { clearTagsList } from "../store/actions/tags";
import { removePathology } from "../store/actions/pathologies";
import { getColorForValue } from "../helpers/getColors";
import { useNavigate } from "react-router-dom";
import { updateDiagnoses } from "../store/reducers/diagnoses/diagnosesSlicer";
import { updateTags } from "../store/reducers/tags/tagsSlicer";
import { addNewTags, removeTag } from "../store/actions/tags";
import { Button } from "react-bootstrap";
import "rc-slider/assets/index.css";
import { handleSliderToggles } from "../store/actions/slider";
import { getSelectorOptionsThunk } from "../store/actions/selectorOptions";
import { rejectService } from "../services";
import ViewerPolling from "./ViewerPolling";

const Slider = (props) => {
  const [selectedPathologyOption, setSelectedPathologyOption] = useState({
    value: "",
    label: "Select pathology",
  });

  const diagnosisOptions = useSelector(
    (state) => state.selectionOptions.options.diagnosis
  );

  const [localDiagnosisOptions, setLocalDiagnosisOptions] = useState(diagnosisOptions);

  const pathologyOptions = useSelector(
    (state) => state.selectionOptions.options.pathologies
  );

  const [localPathologyOptions, setLocalPathologyOptions] = useState(pathologyOptions);

  const [showConfigPrompt, setShowConfigPrompt] = useState(false);

  const tagOptions = useSelector(
    (state) => state.selectionOptions.options.tags
  );

  const [localTagOptions, setLocalTagOptions] = useState(tagOptions);

  const [selectedPathologyOtherOption, setSelectedPathologyOtherOption] =
    useState({
      value: "",
      label: "Other",
    });

  const [selectedDiagnosisOption, setSelectedDiagnosisOption] = useState({
    value: "",
    label: "Select diagnosis",
  });

  const [displayedDiagnosisOption, setDisplayedDiagnosisOption] = useState({
    value: "",
    label: "Select diagnosis",
  });

  const [otherDiagnosisOption, setOtherDiagnosisOption] = useState({
    value: "",
    label: "Other",
  });

  const [isOtherDiagnosisSubmitted, setIsOtherDiagnosisSubmitted] =
    useState(false);

  const currentImage = useSelector((state) => state.next.next);

  const selectedImage = useSelector((state) => state.images?.currentImage);

  const projectId = useSelector((state) => state.projects.selectedProject);

  const [isRemoving, setIsRemoving] = useState(false);

  const rectangleDrawings = useSelector(
    (state) => state.pathologies.pathologies
  );

  const freehandDrawings = useSelector(
    (state) => state.pathologies.freehandDrawings
  );

  const freehandPoints = useSelector(
    (state) => state.pathologies.freehandDrawings.points
  );

  const selectedRectanglesCoords = useSelector(
    (state) => state.pathologies.updateList
  );

  const rectanglesFromFreehand = useSelector(
    (state) => state.pathologies.rectanglesFromFreehand
  );

  const selectedDiagnoses = useSelector((state) => state.diagnoses.diagnoses);

  const [error, setError] = useState("");

  const tags = useSelector((state) => state.tags.tags);
  const [loading, setLoading] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [editablePathologyIndex, setEditablePathologyIndex] = useState(null);
  const slideToggle = useSelector((state) => state.slider.open);

  const [showPrompt, setShowPrompt] = useState(false);
  const [showZeroCountModal, setShowZeroCountModal] = useState(false);
  const [imageSetExists, setImageSetExists] = useState(false);
  const [imageSet, setImageSet] = useState(null);
  const [otherImages, setOtherImages] = useState([]);

  const [currentDatasetIndex, setCurrentDatasetIndex] = useState(0);

  const nextImage = useSelector((state) => state.next.next);

  const dispatch = useDispatch();

  const projectAtLoginData = useSelector(
    (state) => state.projects.projectSelectedAtLogin
  );

  const drawingFlagCheck = useSelector(
    (state) => state.selectionOptions.options.freehand_enabled
  );

  const rejectOptions = [
    { value: "Poor Quality", label: "Poor Quality" },
    { value: "Not referenced image", label: "Not referenced image" },
    { value: "Require Second Opinion", label: "Require Second Opinion" },
    { value: "Other", label: "Other" },
  ];

  const [selectedRejectOption, setSelectedRejectOption] = useState({value: "", label: "Reject"});

  const [prevProjectId, setPrevProjectId] = useState(projectId);

  const [selectedPathologies, setSelectedPathologies] = useState([]);

  const [startPolling, setStartPolling] = useState(false);
  const [clickTimestamp, setClickTimestamp] = useState(null);

  useEffect(() => {
    const selectedPathologies = drawingFlagCheck ? freehandDrawings : rectangleDrawings;
    setSelectedPathologies(selectedPathologies);
  }, [drawingFlagCheck, freehandDrawings, rectangleDrawings]);
  
  // useEffect(() => {
  //   console.log('drawingFlagCheck', drawingFlagCheck);
  //   console.log("selectedPathologies", selectedPathologies);
  //   console.log("freehandDrawings", freehandDrawings);
  //   console.log("rectangleDrawings", rectangleDrawings);
  // }, [selectedPathologies]);

  const handleChange = (e) => {
    console.log('Checkbox State:', e.target.checked);
    props.onToggle(e.target.checked);
  };
  
  const handleLaunchViewer = (timestamp) => {
    setStartPolling(true);
    setClickTimestamp(timestamp)
  };

  const handleRejectOptionChange = (selected) => {
    // Initialize imageIds array with the ID of a non-set image, if applicable
    const imageIds = selectedImage ? [selectedImage._id] : currentImage ? [currentImage._id] : [];

    // If dealing with a set of images, add all their IDs to the imageIds array
    if (otherImages.length > 0) {
      otherImages.forEach(image => {
        if (image._id && !imageIds.includes(image._id)) {
          imageIds.push(image._id);
        }
      });
    }

    if (imageIds.length > 0) {
      rejectService.reject(projectId, imageIds, selected.value, 3) 
        .then(() => {
          handleNextImage();
          console.log('Image rejection successful');
        })
        .catch((error) => {
          console.error('Failed to reject image:', error);
        });
    } else {
      console.log('No images to reject');
    }
  };

  useEffect(() => {
    setLocalDiagnosisOptions(diagnosisOptions);
    setLocalPathologyOptions(pathologyOptions);
    setLocalTagOptions(tagOptions);
  }, [diagnosisOptions, pathologyOptions, tagOptions]);

// Polling and fetching options continuously
useEffect(() => {
  const fetchOptions = async () => {
    await dispatch(getSelectorOptionsThunk(projectId));
    // Update prevProjectId only after successfully fetching new options
    setPrevProjectId(projectId);
  };

  fetchOptions();

  const intervalId = setInterval(fetchOptions, 30000); // Continuous polling every 30 seconds

  return () => clearInterval(intervalId);
}, [projectId, dispatch]);

  // Logic for checking changes and setting the prompt
  useEffect(() => {
    const hasChanges = 
      JSON.stringify(diagnosisOptions) !== JSON.stringify(localDiagnosisOptions) ||
      JSON.stringify(pathologyOptions) !== JSON.stringify(localPathologyOptions) ||
      JSON.stringify(tagOptions) !== JSON.stringify(localTagOptions);
  
    if (prevProjectId === projectId){
      if (hasChanges) {
        setShowConfigPrompt(true);
      }
    }
  }, [diagnosisOptions, pathologyOptions, tagOptions, localDiagnosisOptions, localPathologyOptions, localTagOptions, prevProjectId, projectId]);


  const handlePromptAccept = () => {
    // Update local options with redux options
    setLocalPathologyOptions(pathologyOptions);
    setLocalDiagnosisOptions(diagnosisOptions);
    setLocalTagOptions(tagOptions);
    setShowConfigPrompt(false);
  };

  useEffect(() => {
    if (nextImage) {
      var combinedImages = []
      if (nextImage.image && nextImage.image.otherImages && nextImage.image.otherImages.length > 0) {
        combinedImages = [nextImage.image, ...nextImage.image.otherImages];
      } else {
        combinedImages = [nextImage.image]
      }
      setOtherImages(combinedImages);
      console.log('combinedImages', combinedImages);
    }
  }, [nextImage]);

  const handlePathologyOptionChange = (option) => {
    if (option) {
      setSelectedPathologyOption(option);
      props.getPathologyColor(option);
      props.getPathologyLabel(option);
      props.onChange(option);
      // console.log("selectedPathologyOption", selectedPathologyOption)
    }
    console.log("option", option);
  };

  const handleDiagnosisOptionChange = (option) => {
    if (option.value !== "" && option.label !== "Select diagnosis") {
      // Set the selected diagnosis option based on user selection
      setSelectedDiagnosisOption(option);
      props.getDiagnosisOptions(option);
      
      // After handling the selected option, reset the displayed option to prompt again
      setDisplayedDiagnosisOption({ value: "", label: "Select diagnosis" });
    }
  };

  useEffect(() => {
    if (
      selectedDiagnosisOption.label !== "" &&
      selectedDiagnosisOption.label !== "Other" &&
      !selectedDiagnoses.includes(selectedDiagnosisOption.label)
    ) {
      const newDiagnoses = [
        ...selectedDiagnoses,
        selectedDiagnosisOption.label,
      ];

      if (!(newDiagnoses[0] === "Select diagnosis")) {
        dispatch(updateDiagnoses(newDiagnoses));
      }
    }

    if (
      isOtherDiagnosisSubmitted &&
      !selectedDiagnoses.includes(otherDiagnosisOption.label)
    ) {
      const newDiagnoses = [...selectedDiagnoses, otherDiagnosisOption.label];
      dispatch(updateDiagnoses(newDiagnoses));
      setIsOtherDiagnosisSubmitted(false); // Reset the flag after adding the diagnosis
    }
  }, [
    selectedDiagnosisOption,
    otherDiagnosisOption,
    isOtherDiagnosisSubmitted,
  ]);

  // useEffect(() => {
  //   const handleSliderClose = () => {
  //     if (slideToggle) {
  //       props.onClose();
  //       dispatch(handleSliderToggles(false)); // Dispatch to set slideToggle to false
  //     }
  //   };

  //   handleSliderClose();
  // }, [dispatch, props.onClose, slideToggle]);

  useEffect(() => {
    setSelectedPathologyOption({value:"", label: "Select pathology"})
    console.log("resetTrigger", props.resetTrigger)
  }, [props.resetTrigger])

  const token = localStorage.getItem("token");

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      dispatch(addNewTags([event.target.name]));
    } else {
      dispatch(removeTag(event.target.name));
    }
  };

  // const closeSlider = () => {
    // if (props.isSliderLocked) {
    //   props.onClose();
    //   props.toggleSliderLock();
    // } else {
    //   props.onClose();
    // }
  // }

  const closeSlider = () => {
    if (selectedImage?._id === currentImage?.image._id && 
      (selectedPathologies.length > 0 || selectedDiagnoses.length > 0 || tags.length > 0)) {
      setShowPrompt(true);
    } else {
      if (props.isSliderLocked) {
        props.onClose();
        props.toggleSliderLock();
      } else {
        props.onClose();
      }
    }
  };

  const handleToggleSwicth = () => {
    // if(props.isSliderLocked) {
    //   if (
    //     selectedImage?._id === currentImage?.image._id &&
    //     (selectedPathologies.length > 0 ||
    //       selectedDiagnoses.length > 0 ||
    //       tags.length > 0)
    //   ) {
    //     setShowPrompt(true);
    //   } else {
    //     props.setIsEditable(false);
    //     props.handleEditId(null);
    //     setEditablePathologyIndex(null);
    //     props.toggleSliderLock();
    //   }
    // } else {
      props.toggleSliderLock();
    // }
  };

  const handleProceed = () => {
    setShowPrompt(false);
    clearDiagnosesAndPathologies();
    props.setIsEditable(false);
    props.handleEditId(null);
    setEditablePathologyIndex(null);
    if(props.isSliderLocked) {
      props.toggleSliderLock();
    }
    props.onClose();
  };

const handleEdit = (id) => {
  if (editablePathologyIndex !== null) {
      setEditablePathologyIndex(null); 
      props.handleEditId(null);
      props.setIsEditable(false);
      return; 
  }
  setEditablePathologyIndex(id);
  props.handleEditId(id);
  props.setIsEditable(true);
};

useEffect(() => {
  console.log('selectedImage', selectedImage)
}, [selectedImage])

  // const handleDelete = async (i) => {
  //   const image = selectedImage;
  //   console.log('image!', image)
  //   console.log('i_', i)

  //   setIsRemoving(true);
  //   if (image?.pathologies && image.pathologies?.pathologies?.length > 0) {
  //     const diagnosisData = {
  //       pathology: i,
  //       image: image._id,
  //       diagnosis: image.diagnosis,
  //       diagnoses: image.diagnoses,
  //       tags: image.tags,
  //       pathologies: image.pathologies.pathologies,
  //     };

  //     const updatedImagePathologies = {
  //       pathologies: image.pathologies?.pathologies.filter(
  //         (pathology) => pathology.coords.id !== i
  //       ),
  //     };

  //     const imageUpdate = {
  //       ...image,
  //       pathologies: updatedImagePathologies,
  //     };

  //     const updateDiagnosis = {
  //       pathology: i,
  //       image: selectedImage ? selectedImage._id : currentImage.image._id,
  //       diagnosis: selectedDiagnosisOption.value,
  //       diagnoses: selectedDiagnoses,
  //       tags: tags,
  //       pathologies: image.pathologies?.pathologies.filter(
  //         (pathology) => pathology.coords.id !== i
  //       ),
  //       projectId: projectId,
  //     };

  //     setLoadingStates((prevState) => ({
  //       ...prevState,
  //       [i]: true,
  //     }));

  //     await dispatch(createImageLabel(updateDiagnosis));
  //     setIsRemoving(false);
  //     dispatch(updateCurrentImage(imageUpdate));
  //     dispatch(removeLabelsImage(diagnosisData));
  //     dispatch(removePathology(i));
  //   } else {
  //     setIsRemoving(false);
  //     dispatch(removePathology(i));
  //   }
  // };

  const handleDelete = async (id) => {
    const image = selectedImage;
    setIsRemoving(true);
  
    if (image?.pathologies && image.pathologies?.pathologies?.length > 0) {
      const diagnosisData = {
        pathology: id,
        image: image._id,
        diagnosis: image.diagnosis,
        diagnoses: image.diagnoses,
        tags: image.tags,
        pathologies: image.pathologies.pathologies,
      };
  
      // Filter out both freehand and rectangle pathologies
      const updatedImagePathologies = {
        pathologies: image.pathologies?.pathologies.filter(
          (pathology) => pathology.coords.id !== id
        ),
      };
  
      const imageUpdate = {
        ...image,
        pathologies: updatedImagePathologies,
      };
  
      const updateDiagnosis = {
        pathology: id,
        image: selectedImage ? selectedImage._id : currentImage.image._id,
        diagnosis: selectedDiagnosisOption.value,
        diagnoses: selectedDiagnoses,
        tags: tags,
        pathologies: image.pathologies?.pathologies.filter(
          (pathology) => pathology.coords.id !== id
        ),
        projectId: projectId,
      };
  
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: true,
      }));
  
      await dispatch(createImageLabel(updateDiagnosis));
      setIsRemoving(false);
      dispatch(updateCurrentImage(imageUpdate));
      dispatch(removeLabelsImage(diagnosisData));
      dispatch(removePathology(id));
    } else {
      setIsRemoving(false);
      dispatch(removePathology(id));
    }
  };  
  
  useEffect(() => {
    if (selectedImage && selectedImage.otherImages && selectedImage.otherImages.length >=0) {
    console.log('cur image id', selectedImage._id)
    if (!otherImages.find(i => i._id === selectedImage._id )){
      console.log('not in other')
      var _curImage = selectedImage
      var _other = selectedImage.otherImages
      _curImage = JSON.parse(JSON.stringify(_curImage))
      _curImage.otherImages = null
      var _combined = [_curImage, ..._other]
      setOtherImages(_combined);
    } else {
      console.log('in other')
    }}

    if (selectedImage?.pathologies?.pathologies?.length > 0) {
      const outputPathologies = selectedImage.pathologies.pathologies.map(
        (pathology) => {
          return {
            id: pathology.coords.id,
            x: pathology.coords.x,
            y: pathology.coords.y,
            width: pathology.coords.w,
            height: pathology.coords.h,
            text: pathology.name,
            color: getColorForValue(pathology.name),
          };
        }
      );
      dispatch(addNewPathologies(outputPathologies));
    }

    // Load diagnoses when an image is loaded
    if (selectedImage) {
      if (selectedImage?.pathologies?.diagnoses?.length > 0) {
        dispatch(updateDiagnoses(selectedImage.pathologies.diagnoses));
      } else {
        // dispatch(clearDiagnosesList());
      }
    }

    // Load tags when an image is loaded
    if (selectedImage?.pathologies?.tags?.length > 0) {
      dispatch(updateTags(selectedImage.pathologies.tags));
      // console.log('tags', selectedImage.pathologies.tags);
    } else {
      // dispatch(clearTagsList());
    }
  }, [dispatch, selectedImage]);

  const handleNextImage = async () => {
    try {
      setLoadingNext(true);
      setError("");
      const pathologies = selectedPathologies.map((obj) => {
        const freehandRect = rectanglesFromFreehand.find(rect => rect.id === obj.id);
        return {
          coords: freehandRect 
            ? { id: freehandRect.id, x: freehandRect.x, y: freehandRect.y, w: freehandRect.width, h: freehandRect.height }
            : { id: obj.id, x: obj.x, y: obj.y, w: obj.width, h: obj.height },
          imgId: obj.imgId,
          name: obj.text,
          color: obj.fill,
          freehandpoints: obj.points || null,
        };
      });
      

      // Add updated bounding boxes from selectedRectanglesCoords
      selectedRectanglesCoords.forEach((coord) => {
        const matchingPathology = pathologies.find(
          (p) => p.coords.id === coord.id
        );
        if (matchingPathology) {
          matchingPathology.coords = {
            id: coord.id,
            x: coord.x,
            y: coord.y,
            w: coord.width,
            h: coord.height,
          };
        }
      });

      rectanglesFromFreehand.forEach((coord) => {
        const matchingPathology = pathologies.find(
          (p) => p.id === coord.id
        );
        
        if (matchingPathology) {
          matchingPathology.coords = {
            id: coord.id,
            x: coord.x,
            y: coord.y,
            w: coord.width,
            h: coord.height,
          };
        }
      });

      const diagnosisData = {
        image: selectedImage ? selectedImage._id : currentImage.image._id,
        diagnosis: selectedDiagnosisOption.value,
        diagnoses: selectedDiagnoses,
        tags: tags,
        pathologies: pathologies,
        comments: "",
        projectId: projectId,
      };

      await dispatch(createImageLabel(diagnosisData));
      console.log("diagnosisData", diagnosisData);
      await clearDiagnosesAndPathologies();

      await dispatch(getNextImage(token, projectId, projectAtLoginData));

      setLoadingNext(false);
      dispatch(updateLabelsImage(diagnosisData));

      dispatch(getImageCount(token, projectId));
      const imageCount = dispatch(getImageCount(token, projectId));

      if (imageCount === 0) {
        setShowZeroCountModal(true);
      }
      
      if(props.isSliderLocked === false) {
        props.onClose();
      }
    } catch (error) {
      setError(error);
      setLoadingNext(false);
    }
  };

  const onSelectImage = (index) => {
    setCurrentDatasetIndex(index);
  };

  // const handleSaveImage = async () => {
  //   try {
  //     setError("");
  
  //     // Ensure there's a current dataset to process
  //     if (otherImages && otherImages.length > 0) {
  //       const currentImage = otherImages[currentDatasetIndex];
        
  //       if (currentImage) {
  //         const currentImageId = selectedImage ? selectedImage._id : currentImage._id;
          
  //         // Construct the diagnosis data
  //         const diagnosisData = {
  //           image: currentImageId,
  //           diagnosis: selectedDiagnosisOption.value,
  //           diagnoses: selectedDiagnoses,
  //           tags: tags,
  //           pathologies: selectedPathologies.map(({id, x, y, width, height, text, imgId, color, points}) => ({
  //             coords: { id, x, y, w: width, h: height },
  //             imgId: imgId,
  //             name: text,
  //             color: color,
  //             points: points
  //           })),
  //           comments: "",
  //           projectId: projectId,
  //         };
  //         const diagnosisData2 = {
  //           image: currentImageId,
  //           diagnosis: selectedDiagnosisOption.value,
  //           diagnoses: selectedDiagnoses,
  //           tags: tags,
  //           pathologies: selectedPathologies.filter(p => currentImageId === p.imgId).map(({id, x, y, width, height, text, imgId, color, points}) => ({
  //             coords: { id, x, y, w: width, h: height },
  //             imgId: imgId,
  //             name: text,
  //             color: color,
  //             points: points
  //           })),
  //           comments: "",
  //           projectId: projectId,
  //         };
  //         console.log('save chache 2', currentImageId)
  //         console.log('save chache', diagnosisData)
  //         // Save the label for the current image      

  //         dispatch(updateLabelsImage(diagnosisData));
  //         // diagnosisData.pathologies = diagnosisData.pathologies.filter(path => currentImageId === path.imgId)
  //         console.log('save chache3', diagnosisData2)
  //         await dispatch(createImageLabel(diagnosisData2));
          
  //         // Clear selections for the next image
  //         console.log('currentDatasetIndex', currentDatasetIndex)
  //         // If the current image is not the last one, prepare to process the next image
  //         if (currentDatasetIndex < otherImages.length - 1) {
  //           console.log('Processing next image')
  //           setCurrentDatasetIndex((prevIndex) => prevIndex + 1);
  //           console.log('current dataset index: ', currentDatasetIndex)
  //         } else {
  //           // If it was the last image, reset and fetch the next set
  //           await clearDiagnosesAndPathologies();
  //           setCurrentDatasetIndex(0);
  //           setOtherImages([]);
  //           setImageSetExists(false);
  
  //           await dispatch(getNextImage(token, projectId, projectAtLoginData));
  //         }
  //       }
  //     }
  
  //   } catch (error) {
  //     console.error("Error saving image:", error);
  //     setError(error.toString());
  //     setLoadingNext(false);
  //   }
  // };

  const handleSaveImage = async () => {
    try {
      setError("");
  
      // Ensure there's a current dataset to process
      if (otherImages && otherImages.length > 0) {
        const currentImage = otherImages[currentDatasetIndex];
        
        if (currentImage) {
          const currentImageId = selectedImage ? selectedImage._id : currentImage._id;
          
          // Construct the diagnosis data
          const diagnosisData = {
            image: currentImageId,
            diagnosis: selectedDiagnosisOption.value,
            diagnoses: selectedDiagnoses,
            tags: tags,
            pathologies: selectedPathologies.map(({id, x, y, width, height, text, imgId, color, points}) => {
              const freehandRect = rectanglesFromFreehand.find(rect => rect.id === id);
              return {
                coords: freehandRect ? { id: freehandRect.id, x: freehandRect.x, y: freehandRect.y, w: freehandRect.width, h: freehandRect.height } : { id, x, y, w: width, h: height },
                imgId: imgId,
                name: text,
                color: color,
                freehandpoints: points
              };
            }),
            comments: "",
            projectId: projectId,
          };
          
          const diagnosisData2 = {
            image: currentImageId,
            diagnosis: selectedDiagnosisOption.value,
            diagnoses: selectedDiagnoses,
            tags: tags,
            pathologies: selectedPathologies.filter(p => currentImageId === p.imgId).map(({id, x, y, width, height, text, imgId, color, points}) => {
              const freehandRect = rectanglesFromFreehand.find(rect => rect.id === id);
              return {
                coords: freehandRect ? { id: freehandRect.id, x: freehandRect.x, y: freehandRect.y, w: freehandRect.width, h: freehandRect.height } : { id, x, y, w: width, h: height },
                imgId: imgId,
                name: text,
                color: color,
                freehandpoints: points
              };
            }),
            comments: "",
            projectId: projectId,
          };
  
          console.log('save cache 2', currentImageId);
          console.log('save cache', diagnosisData);
  
          // Save the label for the current image      
          dispatch(updateLabelsImage(diagnosisData));
          console.log('save cache3', diagnosisData2);
          await dispatch(createImageLabel(diagnosisData2));
          
          // Clear selections for the next image
          console.log('currentDatasetIndex', currentDatasetIndex);
          // If the current image is not the last one, prepare to process the next image
          if (currentDatasetIndex < otherImages.length - 1) {
            console.log('Processing next image');
            setCurrentDatasetIndex((prevIndex) => prevIndex + 1);
            console.log('current dataset index: ', currentDatasetIndex);
          } else {
            // If it was the last image, reset and fetch the next set
            await clearDiagnosesAndPathologies();
            setCurrentDatasetIndex(0);
            setOtherImages([]);
            setImageSetExists(false);
  
            await dispatch(getNextImage(token, projectId, projectAtLoginData));
          }
        }
      }
  
    } catch (error) {
      console.error("Error saving image:", error);
      setError(error.toString());
      setLoadingNext(false);
    }
  };  
  
  useEffect(() => {
    const checkImageCount = async () => {
      const response = await dispatch(getImageCount(token, projectId));
      const imageCount = response.data.count;
      console.log('imageCount', imageCount);

      if (imageCount === 0) {
        setShowZeroCountModal(true);
      } else {
        setShowZeroCountModal(false);
      }
      console.log('showZeroCountModal', showZeroCountModal)
    };

    checkImageCount();
  }, [dispatch, token, projectId]);

  const handleNormal = async () => {
    try {
      setLoading(true);
      setError("");
      const diagnosisData = {
        image: selectedImage ? selectedImage._id : currentImage._id,
        diagnosis: "Normal",
        diagnoses: ["Normal"],
        tags: [],
        pathologies: [],
        comments: "",
        projectId: projectId,
      };
      await dispatch(createImageLabel(diagnosisData));
      await clearDiagnosesAndPathologies();
      await dispatch(getNextImage(token, projectId, projectAtLoginData));
      setLoading(false);
      dispatch(getImageCount(token, projectId));
      if(props.isSliderLocked === false) {
        props.onClose();
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const clearDiagnosesAndPathologies = async () => {
    setSelectedDiagnosisOption(() => {
      return { value: "", label: "Select diagnosis" };
    });
    setSelectedPathologyOption(() => {
      return { value: "", label: "Select pathology" };
    });
    await dispatch(clearDiagnosesList());
    await dispatch(clearTagsList());
    await dispatch(clearPathologiesList());
    await dispatch(clearFreehandPathologiesList());
    await dispatch(clearReactanglesFromFreehandList());
  };

// const handleNextSet = async () => {
//   try {
//       setLoadingNext(true);
//       setError("");
//       const diagnosisData = {
//         image: selectedImage ? selectedImage._id : currentImage.image._id,
//         diagnosis: selectedDiagnosisOption.value,
//         diagnoses: selectedDiagnoses,
//         tags: tags,
//         pathologies: selectedPathologies.map(({id, x, y, width, height, text, imgId, color, points}) => ({
//           coords: { id, x, y, w: width, h: height },
//           name: text,
//           imgId: imgId,
//           color: color,
//           points: points
//         })),
//         comments: "",
//         projectId: projectId,
//       };

//       dispatch(updateLabelsImage(diagnosisData));
//       // Ensure using the correct array: otherImages
//       for (let i = 0; i < otherImages.length; i++) {
//           const currentImage = otherImages[i];
//           console.log('currentImage', currentImage)
//           const hasAnnotations = selectedPathologies.length > 0 || selectedDiagnoses.length > 0 || tags.length > 0;
//           if (hasAnnotations) {
//               await saveCurrentImageWithAnnotations(currentImage._id);
//           } else {
//               await markImageAsNormal(currentImage._id);
//           }
//       }

//       await fetchNextSetAndUpdateUI();
//       setLoadingNext(false);
//   } catch (error) {
//       console.error("Error in handleNextSet:", error);
//       setError(error.toString());
//       setLoadingNext(false);
//   }
// };

const handleNextSet = async () => {
  try {
    setLoadingNext(true);
    setError("");
    const diagnosisData = {
      image: selectedImage ? selectedImage._id : currentImage.image._id,
      diagnosis: selectedDiagnosisOption.value,
      diagnoses: selectedDiagnoses,
      tags: tags,
      pathologies: selectedPathologies.map(({id, x, y, width, height, text, imgId, color, points}) => {
        const freehandRect = rectanglesFromFreehand.find(rect => rect.id === id);
        return {
          coords: freehandRect ? { id: freehandRect.id, x: freehandRect.x, y: freehandRect.y, w: freehandRect.width, h: freehandRect.height } : { id, x, y, w: width, h: height },
          name: text,
          imgId: imgId,
          color: color,
          freehandpoints: points
        };
      }),
      comments: "",
      projectId: projectId,
    };

    dispatch(updateLabelsImage(diagnosisData));

    // Ensure using the correct array: otherImages
    for (let i = 0; i < otherImages.length; i++) {
      const currentImage = otherImages[i];
      console.log('currentImage', currentImage);
      const hasAnnotations = selectedPathologies.length > 0 || selectedDiagnoses.length > 0 || tags.length > 0;
      if (hasAnnotations) {
        await saveCurrentImageWithAnnotations(currentImage._id);
      } else {
        await markImageAsNormal(currentImage._id);
      }
    }

    await fetchNextSetAndUpdateUI();
    setLoadingNext(false);
  } catch (error) {
    console.error("Error in handleNextSet:", error);
    setError(error.toString());
    setLoadingNext(false);
  }
};

// const saveCurrentImageWithAnnotations = async (imageId) => {
//     const imagePaths = selectedPathologies.filter(path => imageId === path.imgId)
//     console.log('paths image',imagePaths)
//     const diagnosisData = {
//         image: imageId,
//         diagnosis: selectedDiagnosisOption.value,
//         diagnoses: selectedDiagnoses,
//         tags: tags,
//         pathologies:  imagePaths.map(({id, x, y, width, height, text, points}) => ({
//           coords: { id, x, y, w: width, h: height },
//           name: text,
//           points: points
//         })),
//         comments: "",
//         projectId: projectId,
//     };
   
//     console.log('diagnosisData', diagnosisData);
//     console.log('imageId', imageId);

//     console.log("saveCurrentImageWithAnnotations")

//     await dispatch(createImageLabel(diagnosisData));
// };

const saveCurrentImageWithAnnotations = async (imageId) => {
  const imagePaths = selectedPathologies.filter(path => imageId === path.imgId);
  console.log('paths image', imagePaths);
  
  const diagnosisData = {
    image: imageId,
    diagnosis: selectedDiagnosisOption.value,
    diagnoses: selectedDiagnoses,
    tags: tags,
    pathologies: imagePaths.map(({id, x, y, width, height, text, points}) => {
      const freehandRect = rectanglesFromFreehand.find(rect => rect.id === id);
      return {
        coords: freehandRect ? { id: freehandRect.id, x: freehandRect.x, y: freehandRect.y, w: freehandRect.width, h: freehandRect.height } : { id, x, y, w: width, h: height },
        name: text,
        freehandpoints: points
      };
    }),
    comments: "",
    projectId: projectId,
  };
  
  console.log('diagnosisData', diagnosisData);
  console.log('imageId', imageId);
  console.log("saveCurrentImageWithAnnotations");

  await dispatch(createImageLabel(diagnosisData));
};

const markImageAsNormal = async (imageId) => {
  try {
    setLoadingNext(true);
    setError("");
    const diagnosisData = {
      image: imageId,
      diagnosis: "Normal",
      diagnoses: ["Normal"],
      tags: [],
      pathologies: [],
      comments: "",
      projectId: projectId,
    };
    await dispatch(createImageLabel(diagnosisData));
  } catch (error) {
    setError(error);
    setLoadingNext(false);
  }
    console.log(`Marking image ${imageId} as normal...`);
};

const fetchNextSetAndUpdateUI = async () => {
    // Reset states for the next dataset
    setCurrentDatasetIndex(0);
    setImageSetExists(false);
    setOtherImages([]);

    await clearDiagnosesAndPathologies();

    // Fetch the next set of images
    await dispatch(getNextImage(token, projectId, projectAtLoginData));
    setLoadingNext(false);

    dispatch(getImageCount(token, projectId)).then((imageCount) => {
        if (imageCount === 0) {
            setShowZeroCountModal(true);
        }
    });

    if (props.isSliderLocked === false) {
        props.onClose();
    }
};


const handleNormalSet = async () => {
  setLoading(true);
  try {
    for (const image of otherImages) {
      const imageId = image._id; // Correctly reference the imageId for each image
      
      const diagnosisData = {
        image: imageId, // Use the specific imageId here
        diagnosis: "Normal",
        diagnoses: ["Normal"],
        tags: [],
        pathologies: [],
        comments: "",
        projectId: projectId,
      };

      // Dispatch the action to mark the image as normal, with the correct imageId
      await dispatch(createImageLabel(diagnosisData));
      console.log("diagnosisData", diagnosisData);
      await clearDiagnosesAndPathologies();

      await dispatch(getNextImage(token, projectId, projectAtLoginData));

      setLoadingNext(false);
      dispatch(updateLabelsImage(diagnosisData));

      dispatch(getImageCount(token, projectId));
      const imageCount = dispatch(getImageCount(token, projectId));

      if (imageCount === 0) {
        setShowZeroCountModal(true);
      }
      
      if(props.isSliderLocked === false) {
        props.onClose();
      }
    }
  } catch (error) {
    console.error("Error marking set as normal:", error);
    setError(error.toString());
    setLoading(false);
  }
};

  const imagesSet = selectedImage?.image_set

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (imagesSet && imagesSet.name) {
          setImageSetExists(true);
          setImageSet(imagesSet);
        } else {
          setImageSetExists(false);
        }
  
      } catch (error) {
        setError(error);
      }
    };
  
    fetchInitialData();
    console.log('imageSetExists',imagesSet?.name)
  }, [dispatch, token, projectId, projectAtLoginData, imagesSet]);

  useEffect(() => {
    console.log('launched', startPolling)
  }, [startPolling])

  return (
    <>
      {showZeroCountModal && (
        <div className="zero-count-prompt">
          <p>
            There are no more images to annotate in this project.
          </p>
          <p>
            Please click proceed and select a new project.
          </p>
          <div className="zero-count-prompt-button">
            <button onClick={() => setShowZeroCountModal(false)}>Proceed</button>
          </div>
        </div>
      )}
      {props.isModalOpen && (
        <div
          className={`${!props.openNewWindow ? "col-4 slide-out-div" : ""} ${
            props.isSliderOpen === undefined ? "active" : ""
          }`}
        >
          <div className="slider-body">
            <div>
              <FontAwesomeIcon 
                icon={faArrowRight} 
                onClick={closeSlider}
                color="#ffc848" 
                style={{   float: "right", margin: "22px 20px 0 0", fontSize: "20px" }} 
                title="Close"
              />
            </div>
            <div className="toggle-container">
            <div className="toggle-switch" title={props.isFreeHand ? `Drawing Mode: Freehand`: 'Drawing Mode: Box'}>
              <input
                type="checkbox"
                className="checkbox"
                id="drawing-mode-toggle"
                checked={props.isFreeHand}
                // onChange={handleChange}
                disabled
              />
              <label className="label" htmlFor="drawing-mode-toggle" style={{ borderColor: props.isFreeHand ? '#0E2942' : '#0E2942' }}>
                <span className="inner" />
                <span className="switch">
                  <FontAwesomeIcon icon={props.isFreeHand ? faPencil: faSquare} color={props.isFreeHand ? '#0e2942' : '#3981C8'} />
                </span>
              </label>
            </div>
            <div className="toggle-switch" title="Slide-Out Tools Lock">
              <input
                type="checkbox"
                className="checkbox"
                id="slide-out-lock-toggle"
                checked={props.isSliderLocked}
                onChange={handleToggleSwicth}
              />
              <label className="label" htmlFor="slide-out-lock-toggle" style={{ borderColor: props.isSliderLocked ? '#0E2942' : '#0E2942' }}>
                <span className="inner" />
                <span className="switch">
                  <FontAwesomeIcon icon={props.isSliderLocked ? faLock : faLockOpen} color={props.isSliderLocked ? 'red' : 'green'} />
                </span>
              </label>
            </div>

              </div>
            {showPrompt && selectedImage?._id === currentImage?.image?._id && (
              <div className="prompt">
                <p>
                  The current unsaved annotations will be removed. Would you like to proceed?
                </p>
                <div className="prompts">
                  <button onClick={() => setShowPrompt(false)}>Cancel</button>
                  <button onClick={handleProceed}>Proceed</button>
                </div>
              </div>
            )}

            {startPolling && (
              <ViewerPolling 
                startPolling={startPolling}
                clickTimestamp={clickTimestamp}
              />
            )}

            {imageSetExists && 
            <>
              <FilmStrip
                currentDatasetIndex={currentDatasetIndex}
                onSelectImage={onSelectImage}
                onLaunchViewer={handleLaunchViewer}
              />
            </>
            }

            <div className="dropdown-container">
              {props.pathologyValue && (
                <>
                  <h6 style={{ color: "#fff", margin: "4px 0 6px 10px" }}>
                    New Pathology
                  </h6>
                  <div className="pathology-flex-slider">
                    <div
                      className="dropdown-first-select"
                      title={
                        editablePathologyIndex
                          ? "Please deselect edit first by clicking the tick"
                          : ""
                      }
                    >
                      <DropdownSelect
                        disabled={
                          editablePathologyIndex !== null ||
                          props.isEditable === true
                        }
                        selectedOption={selectedPathologyOption}
                        onChange={handlePathologyOptionChange}
                        options={[
                          ...(editablePathologyIndex
                            ? [{ value: "", label: "Select pathologies" }]
                            : []),
                            ...localPathologyOptions,
                        ]}
                        title={
                          editablePathologyIndex
                            ? "Please deselect edit first by clicking the tick"
                            : ""
                        }
                      />
                    </div>
                    {selectedPathologyOption.label === "Other" && (
                      <div
                        style={{
                          width: "calc(100%-12px)",
                          margin: "10px 12px 16px 9px",
                          display: "flex",
                          height: "36px",
                        }}
                      >
                        <input
                          id="otherDiagnosisInput"
                          name="otherPathologyInput"
                          type="text"
                          placeholder="Other:"
                          list="otherOptionList"
                          style={{ height: "100%" }}
                          onChange={(e) =>
                            setSelectedPathologyOtherOption({
                              value: e.target.value,
                              label: e.target.value,
                            })
                          }
                        ></input>
                        <datalist id="otherOptionList">
                          {pathologyOtherOptions.map((d, i) => {
                            return <option key={i} value={d.value}></option>;
                          })}
                        </datalist>
                        <FontAwesomeIcon
                          className="btn btn-primary slider-button"
                          style={{ color: "#fff", padding: "9.5px 9.5" }}
                          icon={faArrowRight}
                          onClick={() => {
                            if (selectedPathologyOtherOption.value !== "") {
                              handlePathologyOptionChange(
                                selectedPathologyOtherOption
                              );
                            }
                          }}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        background: "#081624",
                        width: "calc(100%-12px)",
                        height: "100%",
                        marginLeft: "9px",
                        marginTop: "12px",
                        marginRight: "12px",
                        marginBottom: "24px",
                        borderRadius: 7,
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {selectedPathologies.map((item, i) => {
                        if (item.imgId === selectedImage._id) {
                        const indices = selectedPathologies
                          .map((pathology) => pathology.text)
                          .reduce((acc, pathology, index) => {
                            if (pathology === item.text) {
                              acc.push(index + 1);
                            }
                            return acc;
                          }, []);
                        const index = indices.indexOf(i + 1);
                        return (
                          <div key={i} style={{ display: "flex" }}>
                            <p style={{ color: "#fff", margin: "20px 14px" }}>
                              {`${index + 1} ${item.text}`}
                            </p>

                            {true && (
                              <FontAwesomeIcon
                                icon={
                                  editablePathologyIndex === item.id
                                    ? faCheck
                                    : faEdit
                                }
                                style={{
                                  color:
                                    editablePathologyIndex === item.id
                                      ? "green"
                                      : "yellow",
                                  marginTop: "23px",
                                  marginRight: "3px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleEdit(item.id);
                                  if (props.isEditable) {
                                    props.setIsEditable(false);
                                  }
                                }}
                              >
                                Edit
                              </FontAwesomeIcon>
                            )}

                            { editablePathologyIndex !== item.id &&
                              <FontAwesomeIcon
                              key={item.id}
                              spin={loadingStates[item.id]}
                              disabled={loadingStates[item.id] || isRemoving}
                              icon={
                                loadingStates[item.id] ? faSpinner : faTrash
                              }
                              style={{
                                color: isRemoving ? "gray" : "red",
                                marginTop: "23px",
                                marginLeft: "3px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: isRemoving ? "not-allowed" : "pointer",
                              }}
                              onClick={() => {
                                handleDelete(item.id).finally(() => {
                                  setLoadingStates((prevState) => ({
                                    ...prevState,
                                    [i]: false,
                                  }));
                                });
                              }}
                            ></FontAwesomeIcon>
                            }
                          </div>
                        );
                      }
                      })}
                    </div>
                    
                    {showConfigPrompt && (
                      <div style={{ display: 'flex', alignItems: 'center', color: '#ffc848', margin: '10px' }}>
                        <FontAwesomeIcon icon={faExclamationCircle} style={{ color: 'red', marginRight: '10px', fontSize: '22px' }} />
                        <p style={{ margin: '0', marginRight: '5px' }}>Project configuration options have changed. Update now?</p>
                        <button style={{ color: '#ffc848', margin: '0 5px 0 5px', border: '2px solid #ffc848', backgroundColor: 'transparent' }} onClick={handlePromptAccept}>Yes</button>
                      </div>
                    )}
                    {editablePathologyIndex ? 
                    <div style={{ display: 'flex', alignItems: 'center', color: '#ffc848', margin: '16px' }}>
                      <FontAwesomeIcon icon={faExclamationCircle} style={{ color: 'red', fontSize: '22px' }} />
                      <p style={{ margin: '5px 0 5px 12px', color: '#ffc848'}}>Please de-select edit first by clicking the tick</p>
                    </div>
                     : null}
                  </div>
                </>
              )}

              {props.diagnosisValue && (
                <div 
                >
                  <h6 style={{ color: "#fff", margin: "4px 0 6px 10px" }}>
                    Diagnosis
                  </h6>
                  <div className="diagnosis-flex-slider">
                    <div className="dropdown-select">
                      <DropdownSelect
                        selectedOption={displayedDiagnosisOption}
                        onChange={handleDiagnosisOptionChange}
                        options={localDiagnosisOptions}
                        placeholder="Select diagnosis"
                      />
                    </div>
                    {!imageSetExists ? (
                      <div className="controls-flex">
                        <Button
                          disabled={
                            loadingNext ||
                            selectedPathologies.length === 0 ||
                            editablePathologyIndex != null ||
                            selectedDiagnoses.length === 0
                          }
                          className="btn btn-primary slider-button"
                          title={
                            selectedPathologies.length === 0
                              ? "Please select a pathology and annotate the image"
                              : editablePathologyIndex != null
                              ? "Please deselect edit first by clicking the tick"
                              : ""
                          }
                          onClick={handleNextImage}
                        >
                          {loadingNext ? (
                            <>
                              <Spinner animation="border" variant="light" size="sm" className="mr-2" />
                              <small>Loading...</small>
                            </>
                          ) : selectedImage?.pathologies ? (
                            <small>Save</small>
                          ) : (
                            <small>Next</small>
                          )}
                        </Button>
                        <Button
                          className="btn btn-success slider-button"
                          onClick={handleNormal}
                          disabled={loading || selectedPathologies.length > 0}
                        >
                          {loading ? (
                            <>
                              <Spinner animation="border" variant="light" size="sm" className="mr-2" />
                              <small>Loading...</small>
                            </>
                          ) : (
                            <small>Normal</small>
                          )}
                        </Button>
                        <div className="dropdown-select reject-dropdown">
                          <DropdownSelect
                            disabled={loading || selectedPathologies.length > 0}
                            selectedOption={selectedRejectOption}
                            onChange={handleRejectOptionChange}
                            options={rejectOptions}
                            placeholder="Reject"
                            className="mb-6 px-2 btn btn-danger"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="controls-flex-imageset" style={{ padding: '10px 0' }}>
                        <Button 
                          className="next-set" 
                          onClick={handleNextSet}
                          style={{ backgroundColor: 'green', borderColor: 'green' }}
                        >
                          {loadingNext ? (
                            <>
                              <Spinner animation="border" variant="light" size="sm" className="mr-2" />
                              <small>Processing...</small>
                            </>
                          ) : (
                            <small>Next Set</small>
                          )}
                        </Button>
                        <Button 
                          onClick={handleNormalSet} 
                          disabled={selectedPathologies.length !== 0 || selectedDiagnoses.length !== 0}
                        >
                          Normal Set
                        </Button>
                        <Button onClick={handleSaveImage}>Save</Button>
                        <div className="dropdown-select reject-dropdown">
                          <DropdownSelect
                            disabled={loading || selectedPathologies.length > 0}
                            selectedOption={selectedRejectOption}
                            onChange={handleRejectOptionChange}
                            options={rejectOptions}
                            placeholder="Reject"
                            className="mb-6 px-2 btn btn-danger"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {selectedDiagnosisOption.label === "Other" && (
                    <div
                      style={{
                        width: "calc(100%-12px)",
                        margin: "10px 12px 16px 9px",
                        display: "flex",
                        height: "36px",
                      }}
                    >
                      <input
                        id="otherDiagnosisInput"
                        name="otherDiagnosisInput"
                        type="text"
                        placeholder="Other:"
                        style={{ height: "100%" }}
                        onChange={(e) =>
                          setOtherDiagnosisOption({
                            value: e.target.value,
                            label: e.target.value,
                          })
                        }
                      />
                      <FontAwesomeIcon
                        className="btn btn-primary slider-button"
                        style={{ color: "#fff", padding: "9.5px 9.5" }}
                        icon={faArrowRight}
                        onClick={() => {
                          if (otherDiagnosisOption.value !== "") {
                            handleDiagnosisOptionChange(otherDiagnosisOption);
                          }
                        }}
                      />
                    </div>
                  )}
                  {error && (
                    <div className="ml-2 alert alert-danger">
                      Something went wrong, Please try again later.
                    </div>
                  )}

                  <div
                    style={{
                      background: "#081624",
                      width: "calc(100%-12px)",
                      height: "100%",
                      marginLeft: "9px",
                      marginTop: "12px",
                      marginRight: "12px",
                      borderRadius: 7,
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedDiagnosisOption.value !== "Other" && (
                      <DiagnosisChip
                        selectedOption={selectedDiagnosisOption.value}
                        handleDiagnosisOptionChange={
                          handleDiagnosisOptionChange
                        }
                        selectedDiagnoses={selectedDiagnoses}
                      />
                    )}
                  </div>
                </div>
              )}

              {props.tagValue && (
                <div>
                  <h6 style={{ color: "white", padding: "8px" }}>Tags</h6>
                  <div
                    style={{
                      background: "#081624",
                      width: "calc(100%-12px)",
                      height: "100%",
                      marginLeft: "9px",
                      marginTop: "12px",
                      marginRight: "12px",
                      borderRadius: 7,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginRight: "20px",
                        marginTop: "10px",
                      }}
                    >
                      {localTagOptions.map((tag, index) => (
                        <div 
                          key={index} 
                          // style={{ display: "flex", alignItems: "center", margin: "5px 20px"  }} 
                          className="tag-container">
                          <input
                            type="checkbox"
                            id={`tag-${index}`} 
                            name={tag}
                            checked={tags.includes(tag)} 
                            onChange={handleCheckboxChange}
                            className="custom-checkbox"
                          />
                          <label 
                            htmlFor={`tag-${index}`} 
                            // style={{ color: "white" }}
                            className="tag-label"
                          >
                            {tag}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

              )}

              {props.enhancementValue && (
                <div style={{ marginTop: "16px" }}>
                  <h6
                    className="text-white mb-2"
                    style={{ color: "white", padding: "10px" }}
                  >
                    Image Enhancement
                  </h6>
                  <div
                    style={{
                      background: "#081624",
                      width: "calc(100%-12px)",
                      height: "100%",
                      marginLeft: "9px",
                      marginTop: "12px",
                      marginRight: "12px",
                      borderRadius: 7,
                    }}
                  >
                    <div className="slidein enhancement bg-navy p-3 mt-2">
                      <div className="row align-items-center">
                        <div className="col-3 pt-2 slider-label">
                          Brightness
                        </div>
                        <div className="col-1 remove-icon">
                          <div className="input-group-prepend">
                            <div className="input-group-text bg-transparent border-0">
                              <FontAwesomeIcon
                                icon={faSun}
                                className="color-white"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-8">
                          <RCSlider
                            min={0}
                            max={200}
                            defaultValue={props.brightness}
                            onChange={(value) => props.setBrightness(value)}
                            className="rc-slider"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-3 pt-2 slider-label">Contrast</div>
                        <div className="col-1 remove-icon">
                          <div className="input-group-prepend">
                            <div className="input-group-text bg-transparent border-0">
                              <FontAwesomeIcon
                                icon={faSun}
                                className="color-white"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-8 reduced-slider">
                          <RCSlider
                            min={0}
                            max={200}
                            defaultValue={props.contrast}
                            onChange={(value) => props.setContrast(value)}
                          />
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-3 pt-2 slider-label">
                          Saturation
                        </div>
                        <div className="col-1 remove-icon">
                          <div className="input-group-prepend">
                            <div className="input-group-text bg-transparent border-0">
                              <FontAwesomeIcon
                                icon={faSun}
                                className="color-white"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-8">
                          <RCSlider
                            min={0}
                            max={200}
                            defaultValue={props.saturate}
                            onChange={(value) => props.setSaturate(value)}
                          />
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-3 pt-2 slider-label">Sepia</div>
                        <div className="col-1 remove-icon">
                          <div className="input-group-prepend">
                            <div className="input-group-text bg-transparent border-0">
                              <FontAwesomeIcon
                                icon={faSun}
                                className="color-white"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-8">
                          <RCSlider
                            min={0}
                            max={200}
                            defaultValue={props.sepia}
                            onChange={(value) => props.setSepia(value)}
                          />
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-3 pt-2 slider-label">Invert</div>
                        <div className="col-1 remove-icon">
                          <div className="input-group-prepend">
                            <div className="input-group-text bg-transparent border-0">
                              <FontAwesomeIcon
                                icon={faSun}
                                className="color-white"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-8">
                          <RCSlider
                            min={0}
                            max={200}
                            defaultValue={props.invert}
                            onChange={(value) => props.setInvert(value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Slider;
