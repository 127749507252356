/**
 * defines pathologies options
 */
export const pathologyOptions = [
  { value: "Atelectasis", label: "Atelectasis", color: "#04d9ff" },
  { value: "Cardiomegaly", label: "Cardiomegaly", color: "#b71aed" },
  { value: "Cavity", label: "Cavity", color: "#1aed4e" },
  { value: "Consolidation", label: "Consolidation", color: "#ed1a4f" },
  { value: "Cysts", label: "Cysts", color: "#4e1aed" },
  { value: "Fibrosis", label: "Fibrosis", color: "#ed4e1a" },
  { value: "Infiltrates", label: "Infiltrates", color: "#1aedb7" },
  { value: "Lymphadenopathy", label: "Lymphadenopathy", color: "#ff03d9" },
  { value: "Nodules", label: "Nodules", color: "#908de8" },
  { value: "Pleural Effusion", label: "Pleural Effusion", color: "#ff2903" },
  { value: "Other", label: "Other", color: "#f9f300" },
];

/**
 * defines pathologyOtherOptions
 */

export const pathologyOtherOptions = [
  { value: "Bone Lesion", label: "Bone Lesion" },
  { value: "Brochiectatasis", label: "Brochiectatasis" },
  { value: "Bullae", label: "Bullae" },
  { value: "Calcified Nodules", label: "Calcified Nodules" },
  { value: "CP Angle Obliteration", label: "CP Angle Obliteration" },
  { value: "Calcified Diaphragm", label: "Calcified Diaphragm" },
  { value: "Elevated Diaphragm", label: "Elevated Diaphragm" },
  { value: "Calcification Egg Shell", label: "Calcification Egg Shell" },
  { value: "Emphysema", label: "Emphysema" },
  { value: "Ground Glass", label: "Ground Glass" },
  { value: "Hyperinflation", label: "Hyperinflation" },
  { value: "Reticular Infiltrates", label: "Reticular Infiltrates" },
  { value: "Interstitial Infiltrates", label: "Interstitial Infiltrates" },
  { value: "Irregular Opacities", label: "Irregular Opacities" },
  { value: "Micronodules", label: "Micronodules" },
  { value: "Mass", label: "Mass" },
  { value: "Pleural Plaques", label: "Pleural Plaques" },
  { value: "Pleural Thickening", label: "Pleural Thickening" },
  { value: "Pneumothorax", label: "Pneumothorax" },
  { value: "Pneumomediastinum", label: "Pneumomediastinum" },
  { value: "Peribronchial Thickening", label: "Peribronchial Thickening" },
  { value: "Post Surgical Hardware", label: "Post Surgical Hardware" },
  {
    value: "Pulmonary Arteries Enlarged",
    label: "Pulmonary Arteries Enlarged",
  },
  { value: "Unfolded Aorta", label: "Unfolded Aorta" },
];

/**
 * Diagnosis color options
 */
export const diagnosisOptions = [
  {
    value: "Pneumonia: High probability",
    label: "Pneumonia: High probability",
  },
  {
    value: "Pneumonia: Intermediate probability",
    label: "Pneumonia: Intermediate probability",
  },
  { value: "Pneumonia: Low probability", label: "Pneumonia: Low probability" },
  { value: "COVID: High probability", label: "COVID: High probability" },
  {
    value: "COVID: Intermediate probability",
    label: "COVID: Intermediate probability",
  },
  { value: "COVID: Low probability", label: "COVID: Low probability" },
  { value: "TB: High probability", label: "TB: High probability" },
  {
    value: "TB: Intermediate probability",
    label: "TB: Intermediate probability",
  },
  { value: "TB: Low probability", label: "TB: Low probability" },
  { value: "Other", label: "Other" },
  { value: "Normal", label: "Normal" },
];
