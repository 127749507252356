import React from 'react'
import Login from './Login'
import HandBanner from '../assets/img/banner-hand.png';

const Banner = () => {

  return (
    <div className='banner-area min-vh-100'>
      <div className='px-sm-5 position-relative index-1 container-fluid header-block'>
        <div className='row no-gutters mt-4 pb-4'>
          <div className={`align-self-center col-md-5 col `}>
            <h1 className='color-white line-height-1 text-white'>RATify | <small>LABELLER</small>
              <br />
            </h1>
            <span className='border-separator'></span>
            <h5 className='text-white mb-4'> Be part of the movement to democratise healthcare. </h5>
            <Login />
            <p className='text-white'>
              <strong className='text-white d-block mb-2'>CONTACT US</strong>
              Should you have any feedback, comments, requests for technical support or other inquiries, please contact us by email: 
              <a className='text-yellow' href="mailto:info@envisionit.ai"> info@envisionit.ai</a>.
            </p>
          </div>
        </div>
      </div>
      <img src={HandBanner} className="img-fluid" alt='' id='hand-banner'></img>
    </div>
  )
}

export default Banner
