import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ResetPassword from "./ResetPassword";
import Main from "./Main";
import LogoLabeller from "../assets/img/logo-icon.svg";
import { authService } from "../services";
import { resetPasswordService } from "../services";
import ProjectList from "./ProjectSelector/ProjectList/ProjectList";
import { setUserSelectedProject } from "../store/actions/projects";
import { getSelectorOptionsThunk } from "../store/actions/selectorOptions";
import { clearPathologiesList } from "../store/actions/pathologies";
import { clearDiagnosesList } from "../store/actions/diagnoses";
import { clearTagsList } from "../store/actions/tags";
import { handleSliderToggles } from "../store/actions/slider";
import { getNextImage, clearCurrentImage } from "../store/actions/images/next";
const token = localStorage.getItem("token");

const MainLayout = (props) => {
  const [showCard, setShowCard] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);
  const { projects } = useSelector((state) => state.projects);

  const selectedPathologies = useSelector(
    (state) => state.pathologies.pathologies
  );

  const projectAtLoginData = useSelector(
    (state) => state.projects.projectSelectedAtLogin
  );

  const selectedDiagnoses = useSelector((state) => state.diagnoses.diagnoses);
  console.log("projects", projects);

  const tags = useSelector((state) => state.tags.tags);

  const [selectedOption, setSelectedOption] = useState(null);
  const [showSelector, setShowSelector] = useState(false);

  // const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openProjectSelect = () => {
    setShowSelector(!showSelector);
  };
  const handleProjectSelect = async (projectId) => {
    // If there's unsaved work for the current image, show the prompt
    if (
      selectedPathologies.length > 0 ||
      selectedDiagnoses.length > 0 ||
      tags.length > 0
    ) {
      setShowSelector(false);
      setShowPrompt(true);
      setSelectedOption(projectId);
      return; // Exit early, don't switch the project until user decides
    }
    proceedWithProjectSelection(projectId);
  };

  const proceedWithProjectSelection = async (projectId) => {
    setShowSelector(true);

    navigate(`/authorized/projects/${projectId}`);
    await dispatch(setUserSelectedProject(projectId));
    await dispatch(getSelectorOptionsThunk(projectId));
    dispatch(clearCurrentImage(null));
    console.log(token)
    await dispatch(getNextImage(token, projectId, projectAtLoginData));
    setShowSelector(false); // Close the selector after selection
  };

  const handleProceed = () => {
    // Clear all pathologies, diagnoses, and tags
    dispatch(clearPathologiesList());
    dispatch(clearDiagnosesList());
    dispatch(clearTagsList());

    setShowPrompt(false);
    setShowPrompt(false);
    dispatch(handleSliderToggles(true));
    if (selectedOption) {
      proceedWithProjectSelection(selectedOption);
    }
  };

  function onCancel() {
    setShowCard(false);
  }

  const handleChangePassword = async () => {
    try {
      await resetPasswordService(currentPassword, newPassword);
      setMessage("Password changed successfully.");
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <div id="MainLayout" className="row-offcanvas row-offcanvas-left">
      <nav className="sidebar-offcanvas sidebar">
        <a href="/" className="navbar-brand">
          <img src={LogoLabeller} class="m-auto d-block img-fluid" alt="" />
        </a>
        <ul className="nav flex-column align-self-end">
          <li className="nav-item mx-auto">
            <a href="/" target="_self" className="nav-link active">
              <div className="pt-1">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="lungs"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  class="svg-inline--fa fa-lungs fa-w-20 fa-2x"
                >
                  <path
                    fill="currentColor"
                    d="M636.11 390.15C614.44 308.85 580.07 231 534.1 
                        159.13 511.98 124.56 498.03 96 454.05 96 415.36 96 384 125.42 384 
                        161.71v60.11l-32.88-21.92a15.996 15.996 0 0 1-7.12-13.31V16c0-8.84-7.16-16-16-16h-16c-8.84 
                        0-16 7.16-16 16v170.59c0 5.35-2.67 10.34-7.12 13.31L256 221.82v-60.11C256 
                        125.42 224.64 96 185.95 96c-43.98 0-57.93 28.56-80.05 63.13C59.93 231 25.56 
                        308.85 3.89 390.15 1.3 399.84 0 409.79 0 419.78c0 61.23 62.48 105.44 125.24 
                        88.62l59.5-15.95c42.18-11.3 71.26-47.47 71.26-88.62v-87.49l-85.84 57.23a7.992 
                        7.992 0 0 1-11.09-2.22l-8.88-13.31a7.992 7.992 0 0 1 2.22-11.09L320 235.23l167.59 
                        111.72a7.994 7.994 0 0 1 2.22 11.09l-8.88 13.31a7.994 7.994 0 0 1-11.09 2.22L384 
                        316.34v87.49c0 41.15 29.08 77.31 71.26 88.62l59.5 15.95C577.52 525.22 640 481.01 
                        640 419.78c0-9.99-1.3-19.94-3.89-29.63z"
                    class=""
                  ></path>
                </svg>
                {/* <img src={Lungs} alt=''/> */}
              </div>
            </a>
          </li>
        </ul>
        <ul className="nav flex-column bottom">
          <li className="nav-item mx-auto">
            <button className="nav-link" onClick={() => openProjectSelect()}>
              <div className="pt-2">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  class="svg-inline--fa fa-list fa-w-20 fa-2x"
                >
                  <path
                    fill="currentColor"
                    d="M80,80 C120,80 160,80 200,80 L200,40 C160,40 120,40 80,40 C40,40 0,40 0,80 C0,120 40,120 80,120 C120,120 160,120 200,120 L200,80 Z M640,40 L240,40 L240,80 L640,80 L640,40 Z M640,120 L240,120 L240,160 L640,160 L640,120 Z M80,200 C120,200 160,200 200,200 L200,160 C160,160 120,160 80,160 C40,160 0,160 0,200 C0,240 40,240 80,240 C120,240 160,240 200,240 L200,200 Z M640,160 L240,160 L240,200 L640,200 L640,160 Z M640,240 L240,240 L240,280 L640,280 L640,240 Z M80,320 C120,320 160,320 200,320 L200,280 C160,280 120,280 80,280 C40,280 0,280 0,320 C0,360 40,360 80,360 C120,360 160,360 200,360 L200,320 Z M640,280 L240,280 L240,320 L640,320 L640,280 Z M640,360 L240,360 L240,400 L640,400 L640,360 Z M80,440 C120,440 160,440 200,440 L200,400 C160,400 120,400 80,400 C40,400 0,400 0,440 C0,480 40,480 80,480 C120,480 160,480 200,480 L200,440 Z M640,400 L240,400 L240,440 L640,440 L640,400 Z"
                  ></path>
                </svg>

                <span class="text">Projects</span>
              </div>
            </button>
          </li>
          <li className="nav-item mx-auto">
            <button className="nav-link" onClick={() => setShowCard(true)}>
              <div className="pt-2">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="user-lock"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  class="svg-inline--fa fa-user-lock fa-w-20 fa-2x"
                >
                  <path
                    fill="currentColor"
                    d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm96 64a63.08 63.08 0 0 1 
                        8.1-30.5c-4.8-.5-9.5-1.5-14.5-1.5h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 
                        48 0 0 0 48 48h280.9a63.54 63.54 0 0 1-8.9-32zm288-32h-32v-80a80 80 0 0 0-160 0v80h-32a32 32 0 0 0-32 32v160a32 
                        32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zM496 432a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm32-144h-64v-80a32 
                        32 0 0 1 64 0z"
                    class=""
                  ></path>
                </svg>
                <span class="text">Change Password</span>
              </div>
            </button>
          </li>
          <li className="nav-item mx-auto" onClick={authService.logout}>
            <a href="/" target="_self" class="nav-link">
              <div class="pt-2">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="sign-out-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="svg-inline--fa fa-sign-out-alt fa-w-16 fa-2x"
                >
                  <path
                    fill="currentColor"
                    d="M497 273L329 441c-15 15-41 
                        4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 
                        436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 
                        0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
                    class=""
                  ></path>
                </svg>
                <span class="text">Logout</span>
              </div>
            </a>
          </li>
        </ul>
      </nav>
      {showSelector && (
        <ProjectList
          handleProjectSelect={handleProjectSelect}
          options={projects}
        />
      )}
      {showPrompt && (
        <div className="prompt">
          <p>
            You're about to switch to another project without saving changes. If
            you proceed, all annotations associated with pathologies, diagnoses,
            or tags will be removed. Do you want to continue?
          </p>
          <div className="prompts">
            <button onClick={() => setShowPrompt(false)}>Cancel</button>
            <button onClick={handleProceed}>Proceed</button>
          </div>
        </div>
      )}
      {showCard && (
        <ResetPassword
          currentPassword={currentPassword}
          setCurrentPassword={setCurrentPassword}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          onSubmit={handleChangePassword}
          onCancel={onCancel}
        />
      )}
      {message && <p>{message}</p>}
      <Main />
    </div>
  );
};

export default MainLayout;
