import axios from "axios";
import {
  setUser,
  setLoggedIn,
  setClientId,
  setRegistered,
  setToken,
} from "../../reducers/user/userSlice";

import { getUserProjects } from "../projects/index.js";

import { apiRoute } from "../../../helpers";

export const login = (credentials) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiRoute()}/auth/v1/login/`,
      credentials
    );
    console.log('login', response.data)
    await dispatch(setUser(response.data));
    if (response.data.token && response.data.refreshToken) {
      dispatch(setLoggedIn(true));
      dispatch(setClientId(response.data?.id));
      dispatch(setToken(response.data.token));
      const projects = response.data.projects;
      localStorage.setItem("token", response.data.token);
      console.log(localStorage.getItem("token"))
      await dispatch(getUserProjects(projects));
    }
    return response.data ? response.data : null;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
};

export const register = (credentials) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiRoute()}/auth/v1/register/`,
      credentials
    );
    if (response) {
      await dispatch(setRegistered(true));
      return response;
    }
    // console.log("response");
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.removeItem("token");
    dispatch(setUser(null));
    dispatch(setLoggedIn(false));
    dispatch(setToken(null));
    dispatch(setClientId(null));
  } catch (error) {
    throw error;
  }
};

// Add this new action to your authentication actions

export const validateTokenAndFetchUserData = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  if (!token) return; // No token, no need to validate

  try {
    // Adjust this to actual API route for validating tokens and fetching user data
    const response = await axios.get(`${apiRoute()}/auth/v1/validate`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    dispatch(setUser(response.data.user));
    dispatch(setLoggedIn(true));
    dispatch(setClientId(response.data.user.id)); 
    dispatch(setToken(token)); // Re-validate current token
    // Optionally, fetch user projects or other related data
    await dispatch(getUserProjects(response.data.user.projects));

  } catch (error) {
    console.log('Session validation failed', error);
    // Optional: clear local storage and Redux store if the token is invalid
    dispatch(logout());
  }
};

