import React, { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AutoLogout.module.scss";
import { logout } from "../../store/actions/authentication";
import { useDispatch, useSelector } from "react-redux";

const AutoLogout = ({ children }) => {
  const [countdown, setCountdown] = useState(30);
  const [showNotification, setShowNotification] = useState(false);
  const loggedIn = useSelector((state) => state.user.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let timer;
    if (loggedIn) {
      timer = setTimeout(() => {
        setShowNotification(true);
      }, 15 * 60 * 1000);
    }
    // Stop the timer when the user is loggedOut
    else {
      clearTimeout(timer);
    }

    // Clear the timer when the component unmounts
    return () => {
      clearTimeout(timer);
    };
  }, [loggedIn]);

  useEffect(() => {
    let countdownTimer;

    // Start the countdown timer when the user is loggedIn
    if (loggedIn) {
      if (showNotification) {
        countdownTimer = setInterval(() => {
          setCountdown((countdown) => countdown - 1);
        }, 1000);
        if (countdown === 0) {
          setShowNotification(false);
          dispatch(logout());
          navigate("/");
        }
      }
    } else {
      setCountdown(30);
    }

    // Clear the countdown timer when the component unmounts or the notification is hidden
    return () => {
      clearInterval(countdownTimer);
    };
  }, [countdown, dispatch, loggedIn, navigate, showNotification]);

  const handleLogout = async () => {
    await dispatch(logout());
    setShowNotification(false);
    navigate("/");
  };

  return (
    <>
      {loggedIn && showNotification && (
        <div className={styles.autoLogout}>
          You're going to be logged out in {countdown} seconds. Do you want to
          stay logged in?
          <div className="buttons">
            <button
              type="button"
              className="btn mb-4 px-5 mr-2 rounded-pill font-weight-bold btn-yellow"
              onClick={() => setShowNotification(false)}
            >
              Stay Logged In
            </button>
            <button
              className="btn mb-4 px-5 ml-2 rounded-pill font-weight-bold btn-yellow"
              onClick={handleLogout}
            >
              Log Out
            </button>
          </div>
        </div>
      )}
      <div className="auto-logout-wrapper">{children}</div>
    </>
  );
};

export default memo(AutoLogout);
