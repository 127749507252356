import { React } from "react";
import { useEffect } from "react";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { updateDiagnoses, removeDiagnoses } from "../../store/reducers/diagnoses/diagnosesSlicer";
import styles from "./DiagnosisChip.module.scss";

import { v4 as uuidv4 } from 'uuid';

export default function DropdownWithChip(props) {
  const { selectedDiagnoses } = props;
  const dispatch = useDispatch();

  const handleChipDelete = (chipToDelete) => () => {
    dispatch(removeDiagnoses(chipToDelete));
  };

  useEffect(() => {
    // console.log('selectedDiagnoses', selectedDiagnoses);
  }, [selectedDiagnoses]);
  useEffect(() => {
    // console.log('props', props);
  }, [props]);

  return selectedDiagnoses.length > 0 ? (
    <div className={styles["diagnosis-chip-container"]}>
      {selectedDiagnoses.map((chip) => {
        return (
          <div 
          key={chip} 
          className={styles["diagnosis-chip"]}
          >
            <Chip
              style={{ color: "#fff" }}
              label={chip}
              onDelete={handleChipDelete(chip)}
              deleteIcon={<CloseIcon style={{ color: "#fff" }} />}
              variant="outlined"
            />
          </div>
        );
      })}
    </div>
  ) : null;
}
